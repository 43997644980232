import {
  Tag,
  TagSize,
  TagVariant,
  TagColor,
  Box,
  AlignItems,
  Space,
  Tooltip,
  IconButton,
  Glyph,
  ButtonVariant,
  ButtonSize,
  ButtonGutter,
} from "@gocardless/flux-react";
import { ReactNode } from "react";

export const ConsentTypeTag = ({
  displayedConsentType,
  toolTipContent,
}: {
  displayedConsentType: string | ReactNode;
  toolTipContent?: string | ReactNode;
}) => (
  <Tag
    size={TagSize.Md}
    variant={TagVariant.Tinted}
    color={TagColor.Neutral}
    css={{ paddingRight: 4, paddingLeft: 8 }}
  >
    <Box
      layout="flex"
      alignItems={AlignItems.Center}
      css={{ whiteSpace: "nowrap" }}
    >
      {displayedConsentType}
      <Space h={0.25} />
      {toolTipContent && (
        <Tooltip message={toolTipContent} positionStrategy="absolute">
          {(triggerProps) => {
            return (
              <IconButton
                data-testid="tooltip-trigger"
                icon={Glyph.Tooltip}
                label="Settings"
                variant={ButtonVariant.TextOnLight}
                size={{ base: ButtonSize.Sm, gutters: ButtonGutter.Sm }}
                {...triggerProps}
              />
            );
          }}
        </Tooltip>
      )}
    </Box>
  </Tag>
);
