import { useState } from "react";
import { isMobile } from "react-device-detect";
import {
  AvailableDebitSchemeEnum,
  BankAuthorisationResource,
  BillingRequestFlowResource,
  BillingRequestResource,
  InstalmentTemplateResource,
  InstitutionResource,
  PaylinkResource,
  PlanResource,
} from "@gocardless/api/dashboard/types";
import {
  Box,
  ButtonLayout,
  ButtonVariant,
  ButtonSize,
  Color,
  ColorPreset,
  FontWeight,
  Glyph,
  H2,
  Interpose,
  LabelledPanel,
  P,
  Separator,
  Space,
  Text,
  Button,
  Icon,
  VisuallyHidden,
  TextAlign,
  JustifyContent,
  AlignItems,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { getTranslatedLink, Link } from "src/common/links";
import {
  BrandedComponentType,
  customerDetailsExist,
  getBrandColorFor,
  isOneOffPaymentFlow,
  isSepaIBPScheme,
} from "src/common/utils";
import BillingRequestDescription from "src/components/shared/BillingRequestDescription";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import BrandedLink from "src/components/shared/BrandedComponents/BrandedLink";
import { Routes } from "src/components/shared/Router";
import TermsAndConditions from "src/components/shared/TermsAndConditions";
import { PayerThemeType } from "src/state";
import { customiseForScheme, Scheme } from "src/common/schemeCustomisations";
import { DirectDebitSchemes } from "src/common/scheme";
import TermsAndCondition from "src/components/shared/Modals/TermsAndConditions";
import ViewESignTerms from "src/components/pages/BankConfirm/ViewESignTerms";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";

import BankDetails from "./BankDetails";
import ConfirmCustomerDetails from "./ConfirmCustomerDetails";
import CreditorDetails from "./CreditorDetails";
import SelfPaymentAuthorisationCheck from "./SelfPaymentAuthorisationCheck";
import ViewDirectDebit from "./ViewDirectDebit";
import PaymentAgreementBody from "./PaymentAgreementBody";

interface BankConfirmViewProps {
  billingRequest: BillingRequestResource;
  bankAuthorisation?: BankAuthorisationResource;
  paylink: PaylinkResource | undefined;
  billingRequestFlow: BillingRequestFlowResource;
  selectedInstitution?: InstitutionResource;
  plan?: PlanResource;
  instalmentTemplate?: InstalmentTemplateResource;
  onAddAnotherAccount: () => void;
  onChangeInstitution: () => void;
  onEditCustomerDetails: () => void;
  onConfirm: () => void;
  setIsSelfAuthorised: (isSelfAuthorised: boolean) => void;
  mandatePDFLink?: string;
  payerTheme?: PayerThemeType;
  sendEvent: (name: string, params?: {}) => void;
  push?: (route: Routes, keyvals: Record<string, unknown>) => void;
  isSelfAuthorised: boolean;
  shouldShowSelfAuthorisationCheck: boolean;
}

export interface BankConfirmViewBodyProps {
  billingRequest: BillingRequestResource;
  billingRequestFlow: BillingRequestFlowResource;
  bankAuthorisation?: BankAuthorisationResource;
  selectedInstitution?: InstitutionResource;
  plan?: PlanResource;
  instalmentTemplate?: InstalmentTemplateResource;
  paylink: PaylinkResource | undefined;
  onChangeInstitution: () => void;
  onAddAnotherAccount: () => void;
  onEditCustomerDetails: () => void;
  payerTheme?: PayerThemeType;
  shouldShowCreditorDetails?: boolean;
  scheme: AvailableDebitSchemeEnum;
}

const eSignTermsLink =
  "https://assets.ctfassets.net/40w0m41bmydz/7h0wPfCRdbE9Rg8Ortu2cY/634522475874ec11a01bf9890bf8e483/GoCardless_E-Sign_Terms_-_Payers_-_October_2023.pdf";

const BankConfirmView = ({
  billingRequest,
  billingRequestFlow,
  bankAuthorisation,
  selectedInstitution,
  plan,
  instalmentTemplate,
  onChangeInstitution,
  onAddAnotherAccount,
  onEditCustomerDetails,
  onConfirm,
  mandatePDFLink,
  payerTheme,
  paylink,
  sendEvent,
  setIsSelfAuthorised,
  isSelfAuthorised,
  shouldShowSelfAuthorisationCheck,
}: BankConfirmViewProps) => {
  const { i18n } = useLingui();
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false);
  const [termsAndConditionsError, setTermsAndConditionsError] = useState(false);
  const onChecked = (checkedState: boolean) => {
    setAcceptedTermsAndConditions(checkedState);
  };

  const bank_name = selectedInstitution?.name || "bank";
  const hasMandateRequest = Boolean(billingRequest?.mandate_request);
  const bankAuthorisationReady = Boolean(
    bankAuthorisation && bankAuthorisation.url
  );

  const isMobileAuthorisation = isOneOffPaymentFlow(billingRequest) && isMobile;

  const scheme = billingRequest?.mandate_request
    ?.scheme as AvailableDebitSchemeEnum;
  const paymentRequestScheme = billingRequest?.payment_request
    ?.scheme as Scheme;

  // show creditor details panel only for becs, becs_nz, sepa_core
  const shouldShowCreditorDetails = [
    AvailableDebitSchemeEnum.Becs,
    AvailableDebitSchemeEnum.BecsNz,
    AvailableDebitSchemeEnum.SepaCore,
  ].includes(scheme);

  const isSepaIBPFlow = isSepaIBPScheme(billingRequest);
  const isACHMandate =
    billingRequest?.mandate_request?.scheme === AvailableDebitSchemeEnum.Ach;

  const termsAndConditionsText = (
    <Trans id="confirm-details-page.bank-details.terms-of-use-confirmation">
      I confirm I have read and agree to the{" "}
      <BrandedLink
        variant={ButtonVariant.Inline}
        href={getTranslatedLink(Link.PayerTerms, i18n, billingRequest)}
        target="_blank"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Text weight={FontWeight.SemiBold}>Payer Terms of Use</Text>
      </BrandedLink>
    </Trans>
  );

  const termsAndConditions = (
    <TermsAndConditions
      checked={acceptedTermsAndConditions}
      onChecked={onChecked}
      showError={!acceptedTermsAndConditions && termsAndConditionsError}
      text={termsAndConditionsText}
    />
  );

  const onClick = () => {
    if (isSepaIBPFlow) {
      setTermsAndConditionsError(!acceptedTermsAndConditions);
      if (acceptedTermsAndConditions) {
        onConfirm();
      }
      return;
    }
    onConfirm();
  };

  const desktopButton = (
    <BrandedButton
      data-testid="billing-request.bank-confirm.default-cta-button"
      backgroundColor={getBrandColorFor(
        BrandedComponentType.Button,
        payerTheme
      )}
      type="submit"
      rightIcon={Glyph.ArrowForward}
      size={ButtonSize.Lg}
      layout={ButtonLayout.Inline}
      variant={ButtonVariant.PrimaryOnLight}
      onClick={onClick}
    >
      <Trans id="confirm-details-page.form.confirm-and-continue-button">
        Confirm and continue
      </Trans>
    </BrandedButton>
  );

  const mobileButton = (
    <BrandedButton
      backgroundColor={getBrandColorFor(
        BrandedComponentType.Button,
        payerTheme
      )}
      variant={ButtonVariant.PrimaryOnLight}
      size={ButtonSize.Lg}
      rightIcon={Glyph.ArrowForward}
      onClick={onClick}
      layout={[ButtonLayout.Full, ButtonLayout.Inline]}
    >
      <Trans id="bank-connect-page.bank-authorization.link">
        Continue to your bank
      </Trans>
    </BrandedButton>
  );

  const mobileLoading = (
    <Button
      disabled
      variant={ButtonVariant.PrimaryOnLight}
      layout={[ButtonLayout.Full, ButtonLayout.Inline]}
      size={ButtonSize.Lg}
      className="fs-unmask"
    >
      <Icon size="24px" name={Glyph.Spinner} />
      <VisuallyHidden>Loading...</VisuallyHidden>
    </Button>
  );

  const renderButtonBasedOnDevice = () => {
    if (isMobileAuthorisation) {
      return bankAuthorisationReady ? mobileButton : mobileLoading;
    } else {
      return desktopButton;
    }
  };

  const displayACHandPADConsentSubheader =
    billingRequest?.mandate_request?.scheme &&
    [
      `${AvailableDebitSchemeEnum.Ach}`,
      `${AvailableDebitSchemeEnum.Pad}`,
    ].includes(billingRequest.mandate_request.scheme);

  return (
    <Box>
      {/* Display Header */}
      <H2
        size={6}
        weight={600}
        data-testid="billing-request.bank-confirm.header"
      >
        {customiseForScheme({
          scheme: hasMandateRequest ? (scheme as Scheme) : paymentRequestScheme,
          key: "billing-request.bank-confirm.header",
          params: { bank_name, billingRequest },
        })}
      </H2>

      {displayACHandPADConsentSubheader && (
        <P size={3} weight={FontWeight.Light} spaceAbove={1}>
          {/* ACH and PAD are North American schemes, 
          so should use Americanized spelling */}
          <Trans>We need your consent to set up this authorization</Trans>
        </P>
      )}

      {billingRequest.payment_request && (
        <>
          <Space v={0.5} />
          <P size={3} weight={FontWeight.Light}>
            {customiseForScheme({
              scheme: paymentRequestScheme,
              key: "billing-request.bank-confirm.header-notification",
              params: {
                billingRequest,
              },
            })}
          </P>
        </>
      )}
      <Space v={1.5} />

      {customiseForScheme({
        scheme: (scheme as Scheme) || paymentRequestScheme,
        key: "billing-request.bank-confirm.body",
        params: {
          billingRequest,
          billingRequestFlow,
          bankAuthorisation,
          selectedInstitution,
          plan,
          instalmentTemplate,
          paylink,
          onChangeInstitution,
          onAddAnotherAccount,
          onEditCustomerDetails,
          payerTheme,
          shouldShowCreditorDetails,
          scheme,
        },
      })}

      {!isSepaIBPFlow && <Space v={2} />}
      {hasMandateRequest && DirectDebitSchemes.includes(scheme) ? (
        <>
          {shouldShowSelfAuthorisationCheck && (
            <SelfPaymentAuthorisationCheck
              setIsSelfAuthorised={setIsSelfAuthorised}
              isSelfAuthorised={isSelfAuthorised}
              sendEvent={sendEvent}
              scheme={scheme}
            />
          )}

          {isSepaIBPFlow && termsAndConditions}

          <Box
            layout="flex"
            flexDirection="column"
            alignItems={AlignItems.Center}
          >
            {isACHMandate && (
              <Box spaceBelow={1.5}>
                <P
                  size={2}
                  textAlign={TextAlign.Center}
                  color={ColorPreset.TextOnLight_03}
                >
                  <Trans>
                    By continuing, you consent to the terms of your ACH Debit
                    Authorization, GoCardless&apos; E-Sign Terms, and your name
                    being used in print as an electronic representation of this
                    consent.
                  </Trans>
                </P>
              </Box>
            )}

            <BrandedButton
              data-testid="billing-request.bank-confirm.direct-debit-cta-button"
              backgroundColor={getBrandColorFor(
                BrandedComponentType.Button,
                payerTheme
              )}
              type="submit"
              leftIcon={Glyph.Padlock}
              size={[ButtonSize.Md, null, ButtonSize.Lg]}
              layout={ButtonLayout.Inline}
              variant={ButtonVariant.PrimaryOnLight}
              onClick={onClick}
            >
              {translateForScheme({
                scheme: billingRequest?.mandate_request
                  ?.scheme as AvailableDebitSchemeEnum,
                translationKey: "confirm-details-page.form.confirm-button",
              })}
            </BrandedButton>
            {/* Todo: Change the text inside based on the scheme */}
            {/* Don't show mandate pdf link  */}
            {/* if for some reason our API to mandate pdf fails  */}
            {mandatePDFLink && (
              <Box spaceAbove={1.5}>
                <ViewDirectDebit
                  payerTheme={payerTheme}
                  mandatePDFLink={mandatePDFLink}
                  billingRequest={billingRequest}
                  sendEvent={sendEvent}
                />
              </Box>
            )}
            {isACHMandate && (
              <Box spaceAbove={mandatePDFLink ? 1 : 1.5}>
                <ViewESignTerms
                  payerTheme={payerTheme}
                  eSignTermsLink={eSignTermsLink}
                  billingRequest={billingRequest}
                  sendEvent={sendEvent}
                />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <>
          {isSepaIBPFlow && termsAndConditions}
          <TermsAndCondition
            payerTheme={payerTheme}
            billingRequest={billingRequest}
          />
          <Space v={2} />
          <Box
            width="100%"
            layout="flex"
            justifyContent={JustifyContent.Center}
          >
            {renderButtonBasedOnDevice()}
          </Box>
        </>
      )}
    </Box>
  );
};

const BankConfirmViewDefaultBody = ({
  billingRequest,
  billingRequestFlow,
  selectedInstitution,
  plan,
  instalmentTemplate,
  paylink,
  onChangeInstitution,
  onAddAnotherAccount,
  onEditCustomerDetails,
  payerTheme,
  shouldShowCreditorDetails,
  scheme,
  bankAuthorisation,
}: BankConfirmViewBodyProps) => {
  const shouldShowCustomerDetails = customerDetailsExist({
    billingRequest,
  });

  return (
    <LabelledPanel
      data-testid="billing-request.bank-confirm.default-body"
      heading={
        <BillingRequestDescription
          billingRequest={billingRequest}
          billingRequestFlow={billingRequestFlow}
          plan={plan}
          instalmentTemplate={instalmentTemplate}
          paylink={paylink}
          // Require the extended confirmation for the mandate, if the scheme
          // requires it.
          mandateRequestExtendedConfirmation={true}
        />
      }
      headerBg={Color.Greystone_100}
      headerBorder={Color.Greystone_100}
      gutterH={2}
      gutterV={2}
    >
      <Interpose node={<Separator spacing={[[2, 0]]} />}>
        {shouldShowCreditorDetails && (
          <CreditorDetails
            billingRequest={billingRequest}
            billingRequestFlow={billingRequestFlow}
            scheme={scheme}
          />
        )}

        {shouldShowCustomerDetails && (
          <ConfirmCustomerDetails
            billingRequest={billingRequest}
            billingRequestFlow={billingRequestFlow}
            onEditCustomerDetails={onEditCustomerDetails}
            payerTheme={payerTheme}
            bankAuthorisation={bankAuthorisation}
          />
        )}

        <BankDetails
          billingRequest={billingRequest}
          billingRequestFlow={billingRequestFlow}
          institution={selectedInstitution}
          onChangeInstitution={onChangeInstitution}
          onAddAnotherAccount={onAddAnotherAccount}
          payerTheme={payerTheme}
        />
      </Interpose>
    </LabelledPanel>
  );
};

export {
  BankConfirmViewDefaultBody,
  PaymentAgreementBody as BankConfirmViewPaymentAgreementBody,
};
export default BankConfirmView;
