import { Currency } from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  Color,
  ColorPreset,
  Field,
  FontWeight,
  InputGutter,
  Label,
  Option,
  Select,
  Separator,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { CurrencySymbolMap } from "src/common/currency";

interface CurrencySelectorProps {
  currencyList: Currency[];
  currentCurrency?: Currency;
  onChange?: (currency: Currency) => void;
}

export const setCurrentCurrencyFirst = (
  currencyList: Currency[],
  currentCurrency?: Currency
): Currency[] => {
  if (!currentCurrency) {
    return currencyList;
  }
  const filteredList = currencyList.filter(
    (currency: Currency) => currency !== currentCurrency
  );

  return [currentCurrency, ...filteredList];
};

const CURRENCY_SELECTOR_NAME = "currencySelector";

const CurrencySelector = ({
  currencyList,
  currentCurrency,
  onChange,
}: CurrencySelectorProps) => {
  const list = setCurrentCurrencyFirst(currencyList, currentCurrency);
  const changeCurrency = (currency: Currency) => onChange && onChange(currency);

  return (
    <Field>
      <Box
        layout="flex"
        alignItems={AlignItems.Center}
        bg={Color.Greystone_400}
        borderRadius={1.5}
        css={{ paddingLeft: "16px" }}
      >
        <Box spaceAfter={1}>
          <Label htmlFor={CURRENCY_SELECTOR_NAME}>
            <Text
              preset={TypePreset.Body_01}
              weight={FontWeight.Normal}
              color={ColorPreset.TextOnLight_02}
            >
              <Trans id="billing-request-flow.currency-selector.label-text">
                Pay with
              </Trans>
            </Text>
          </Label>
        </Box>
        {currentCurrency && (
          <Box spaceAfter={0.5}>
            <Text weight={FontWeight.Bold}>
              {CurrencySymbolMap[currentCurrency]}
            </Text>
          </Box>
        )}
        <Separator
          direction="inline-vertical"
          length="22px"
          color={Color.Greystone_800}
        />
        <Select
          name={CURRENCY_SELECTOR_NAME}
          defaultValue=""
          id={CURRENCY_SELECTOR_NAME}
          css={{
            boxShadow: "none",
            backgroundColor: "transparent",
            fontWeight: FontWeight.Bold,
            borderRadius: "0 25px 25px 0",
            paddingLeft: "8px",
          }}
          gutter={InputGutter.Sm}
          onChange={({ target: { value } }) =>
            changeCurrency(value as Currency)
          }
        >
          {list.map((currency) => {
            return (
              <Option value={currency} key={currency}>
                {currency}
              </Option>
            );
          })}
        </Select>
      </Box>
    </Field>
  );
};

export default CurrencySelector;
