import {
  AvailableDebitSchemeEnum,
  Currency,
} from "@gocardless/api/dashboard/types";
import {
  Visibility,
  Box,
  AlignItems,
  JustifyContent,
  Hint,
  FormFieldStatus,
} from "@gocardless/flux-react";
import { PaymentVariant } from "src/common/payments";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";

import CurrencySelector from "../../../CurrencySelector";

export interface IMandateCurrencySelector {
  paymentVariant: PaymentVariant;
  errorMessage?: string;
  availableCurrencies: Currency[];
  currentCurrency: Currency;
  onChange: (newCurrency: Currency) => void;
  scheme: AvailableDebitSchemeEnum;
}

export const MandateCurrencySelector = ({
  paymentVariant,
  errorMessage,
  availableCurrencies,
  currentCurrency,
  onChange: handleChange,
  scheme,
}: IMandateCurrencySelector) => {
  const isVisible =
    paymentVariant === PaymentVariant.DirectDebitMandate ||
    paymentVariant === PaymentVariant.VerifiedMandate ||
    paymentVariant === PaymentVariant.VariableRecurringPayments;

  return (
    <Visibility visible={isVisible ? "block" : "none"}>
      <Box
        layout="flex"
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.SpaceBetween}
        spaceAbove={1}
      >
        <Box height={20}>
          {translateForScheme({
            scheme: scheme,
            translationKey: "billing-request-description-info-scheme-logo",
            params: {},
          })}
        </Box>
        <CurrencySelector
          currencyList={availableCurrencies}
          currentCurrency={currentCurrency}
          onChange={handleChange}
        />
      </Box>
      {errorMessage && (
        <Hint status={FormFieldStatus.Danger}>{errorMessage}</Hint>
      )}
    </Visibility>
  );
};
