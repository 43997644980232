import { isMobile } from "react-device-detect";
import { Trans } from "@lingui/macro";
import {
  Adapter,
  AvailableDebitSchemeEnum,
  HomeSchemeEnum,
  BillingRequestResource,
  BankAuthorisationResource,
} from "@gocardless/api/dashboard/types";
import {
  Avatar,
  AvatarSize,
  AlignItems,
  Banner,
  BannerStatus,
  BannerVariant,
  BannerLayout,
  Box,
  ButtonVariant,
  Color,
  ColorScheme,
  ColorPreset,
  FontWeight,
  Glyph,
  JustifyContent,
  P,
  Space,
  Tag,
  TagColor,
  TagSize,
  TagVariant,
  TextAlign,
  Text,
  TypePreset,
  BannerLeftAccessoryType,
} from "@gocardless/flux-react";
import Checklist from "src/components/shared/Checklist";
import { PayerThemeType } from "src/state";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import PayToLogo from "src/assets/svg/payto-logo.svg";
import BankIDLogo from "src/assets/svg/bankid-logo-small.svg";
import SpinnerWithImage from "src/components/shared/SpinnerWithImage";
import { LogoResource } from "src/components/pages/BankWait/WaitingOnBankAuthorisation/WaitingOnBankAuthorisationView";
import {
  BankConfirmViewDefaultBody,
  BankConfirmViewPaymentAgreementBody,
  BankConfirmViewBodyProps,
} from "src/components/pages/BankConfirm/BankConfirm.view";
import { InstitutionSelectionHeader } from "src/components/pages/BankSelect/InstitutionSelection";
import PayerTermsOfUseForVRP from "src/components/shared/Modals/TermsOfUse/PayerTermsOfUseForVRP";
import PayerTermsOfUseForUK from "src/components/shared/Modals/TermsOfUse/PayerTermsOfUseForUK";
import PayerTermsOfUseForEU from "src/components/shared/Modals/TermsOfUse/PayerTermsOfUseForEU";
import { useI18n } from "src/common/i18n";
import { CountryCodes } from "src/common/country";

import {
  BrandedComponentType,
  getBankAuthorisationAdapter,
  getBrandColorFor,
  isPaymentAgreementEditable,
  isVRPFlow,
  isSEPASchemeMandateOnly,
  isAchMxExperimentMandate,
  isEligibleForIBPABExperiment,
} from "../utils";
import { convertToLocaleDateString } from "../date-time";

// All possible customisation keys must be declared here
// We're assuming that all schemes must have all the keys defined, which
// makes it type-safe. We can consider adding optional keys with a risk
export interface CustomisationKey {
  "billing-request.show-locale-switcher": () => boolean;
  "billing-request.match-default-locale": () => boolean;
  "billing-request.collect-customer-details.corporate-payers-note": ({
    billingRequest,
  }: {
    billingRequest?: BillingRequestResource;
  }) => React.ReactElement | undefined;
  "billing-request.bank-wait-page.loading-icon": (
    params: LogoResource
  ) => React.ReactElement;
  "billing-request.bank-wait-page.body": ({
    billingRequest,
    onRetry,
    payerTheme,
    shouldTakenToBankAuthCompletionView,
  }: {
    billingRequest: BillingRequestResource;
    onRetry?: () => {};
    payerTheme?: PayerThemeType;
    shouldTakenToBankAuthCompletionView?: boolean;
    bankAuthorisation?: BankAuthorisationResource;
  }) => React.ReactElement | null;
  "billing-request.bank-confirm.header": ({
    bank_name,
    billingRequest,
  }: {
    bank_name?: string;
    billingRequest?: BillingRequestResource;
  }) => React.ReactElement | null;
  "billing-request.bank-confirm.header-notification": ({
    billingRequest,
  }: {
    billingRequest: BillingRequestResource;
  }) => React.ReactElement | null;
  "billing-request.bank-confirm.body": (
    params: BankConfirmViewBodyProps
  ) => React.ReactElement | null;
  "billing-request.bank-confirm.show-fee-notice": () => boolean;
  "billing-request.bank-confirm.payment-agreement.notice": ({
    billingRequest,
  }: {
    billingRequest?: BillingRequestResource;
  }) => React.ReactElement | null;
  "billing-request.bank-confirm.payment-agreement.editable-bank-account": ({
    bankAuthorisation,
  }: {
    bankAuthorisation?: BankAuthorisationResource;
  }) => boolean;
  "billing-request.bank-confirm.payment-agreement.editable-customer-details": ({
    bankAuthorisation,
    billingRequest,
    country_code,
  }: {
    bankAuthorisation?: BankAuthorisationResource;
    billingRequest?: BillingRequestResource;
    country_code?: CountryCodes;
  }) => boolean;
  "billing-request.bank-confirm.bank-details.show-not-your-bank-link": ({
    billingRequest,
  }: {
    billingRequest: BillingRequestResource;
  }) => boolean;
  "billing-request.bank-select.header": ({
    billingRequest,
  }: {
    billingRequest: BillingRequestResource;
  }) => React.ReactElement | null;
  "billing-request.bank-select.payment-authorisation-notice": ({
    billingRequest,
  }: {
    billingRequest: BillingRequestResource;
  }) => React.ReactElement | null;
  "billing-request.bank-select.show-account-number-notice": ({
    billingRequest,
  }: {
    billingRequest: BillingRequestResource;
  }) => boolean;
  "billing-request.bank-connect.terms-and-conditions": ({
    billingRequest,
    whatIsGCButton,
    termsOfUseButton,
    privacyPolicyButton,
  }: {
    billingRequest: BillingRequestResource;
    whatIsGCButton: React.ReactElement;
    termsOfUseButton: React.ReactElement;
    privacyPolicyButton: React.ReactElement;
  }) => React.ReactElement | null;
  "billing-request.modal.payer-terms-of-use": ({
    billingRequest,
  }: {
    billingRequest: BillingRequestResource;
  }) => React.ReactElement | null;
  "billing-request.bank-connect.qr-code-scanner.header": ({
    billingRequest,
  }: {
    billingRequest: BillingRequestResource;
  }) => React.ReactElement;
  "billing-request.bank-connect.qr-code-scanner.description": ({
    bankName,
    billingRequest,
  }: {
    bankName?: string;
    billingRequest: BillingRequestResource;
  }) => React.ReactElement;
  "billing-request.bank-connect.qr-code-scanner.show-learn-more": () => boolean;
  "billing-request.bank-connect.qr-code-scanner.needs-mobile-interstitial": () => boolean;
  "bank-data-access-request-page.request-account-info.title": ({
    billingRequest,
  }: {
    billingRequest: BillingRequestResource;
  }) => React.ReactElement;
  "bank-data-access-request-page.request-account-info.description": ({
    billingRequest,
  }: {
    billingRequest: BillingRequestResource;
  }) => React.ReactElement;
  "bank-data-access-request-page.request-account-info.checklist": ({
    billingRequest,
  }: {
    billingRequest: BillingRequestResource;
  }) => React.ReactElement;
}

export type Scheme =
  | HomeSchemeEnum.FasterPayments
  | HomeSchemeEnum.SepaCreditTransfer
  | HomeSchemeEnum.SepaInstantCreditTransfer
  | HomeSchemeEnum.PayTo
  | HomeSchemeEnum.Ach
  | HomeSchemeEnum.Autogiro
  | HomeSchemeEnum.Bacs
  | HomeSchemeEnum.Becs
  | HomeSchemeEnum.BecsNz
  | HomeSchemeEnum.Betalingsservice
  | HomeSchemeEnum.Pad
  | AvailableDebitSchemeEnum.SepaCore;

const defaultConfig: CustomisationKey = {
  "billing-request.collect-customer-details.corporate-payers-note": () =>
    undefined,
  "billing-request.show-locale-switcher": () => true,
  "billing-request.match-default-locale": () => true,
  "billing-request.bank-wait-page.loading-icon": (logoDetails) => (
    <SpinnerWithImage
      imageSource={logoDetails?.icon_url}
      altText={logoDetails?.name || "Institution/Scheme icon"}
    />
  ),
  "billing-request.bank-wait-page.body": ({ billingRequest }) => (
    <Box
      layout="flex"
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
    >
      <P textAlign={TextAlign.Center}>
        <WaitingText billingRequest={billingRequest} />
      </P>
    </Box>
  ),
  "billing-request.bank-confirm.header": () => (
    <Trans>Confirm your details</Trans>
  ),
  "billing-request.bank-confirm.body": (params) => (
    <BankConfirmViewDefaultBody {...params} />
  ),
  "billing-request.bank-confirm.header-notification": () => null,
  "billing-request.bank-select.header": () => <InstitutionSelectionHeader />,
  "billing-request.bank-select.payment-authorisation-notice": () => null,
  "billing-request.bank-select.show-account-number-notice": () => true,
  "billing-request.bank-confirm.show-fee-notice": () => false,
  "billing-request.bank-confirm.payment-agreement.notice": () => null,
  "billing-request.bank-confirm.payment-agreement.editable-bank-account": () =>
    true,
  "billing-request.bank-confirm.payment-agreement.editable-customer-details":
    () => true,
  "billing-request.bank-confirm.bank-details.show-not-your-bank-link": () =>
    true,
  "billing-request.bank-connect.terms-and-conditions": () => null,
  "billing-request.modal.payer-terms-of-use": () => null,
  "billing-request.bank-connect.qr-code-scanner.header": () => {
    return (
      <Trans id="bank-connect-page.qr-code-scanner.pay-with-mobile-banking-app-heading">
        Pay with your phone
      </Trans>
    );
  },
  "billing-request.bank-connect.qr-code-scanner.description": ({
    bankName,
  }) => {
    return (
      <Trans id="bank-connect-page.qr-code-scanner.pay-with-mobile-banking-app-description">
        Scan the QR code with your phone’s camera to pay with the{" "}
        {bankName ?? "institution"} app.
      </Trans>
    );
  },
  "billing-request.bank-connect.qr-code-scanner.show-learn-more": () => true,
  "billing-request.bank-connect.qr-code-scanner.needs-mobile-interstitial":
    () => true,
  "bank-data-access-request-page.request-account-info.title": ({
    billingRequest,
  }) => {
    return (
      <P size={3} weight={FontWeight.SemiBold}>
        <Trans id="bank-data-access-request-page.request-account-info.title">
          GoCardless would like to access your payment account information to
          set up your Direct Debit with {billingRequest.creditor_name}
        </Trans>
      </P>
    );
  },
  "bank-data-access-request-page.request-account-info.description": () => {
    return (
      <P>
        <Trans id="bank-data-access-request-page.request-account-info.description">
          GoCardless will make a one-off request to your bank or building
          society for your payment account information*. Access to this
          information will be permitted for 24 hours. To do this, you will be
          entering an agreement with GoCardless on their T&amp;Cs.
        </Trans>
      </P>
    );
  },
  "bank-data-access-request-page.request-account-info.checklist": ({
    billingRequest,
  }) => {
    return (
      <>
        <P weight={FontWeight.SemiBold} spaceBelow={1}>
          <Trans id="bank-data-access-request-page.gocardless-permissions-checklist.title">
            GoCardless will be able to:
          </Trans>
        </P>

        <Checklist
          icon={
            <Avatar
              icon={Glyph.Tick}
              bg={Color.Greystone_1400}
              color={Color.White}
              size={AvatarSize.Xs}
            />
          }
          items={[
            <Trans
              id="bank-data-access-request-page.gocardless-permissions-checklist.one"
              key="bank-data-access-request-page.gocardless-permissions-checklist.one"
            >
              View your name
            </Trans>,
            // Don't show the sort code for the Sepa VMs flow
            !isSEPASchemeMandateOnly(billingRequest) && (
              <Trans
                id="bank-data-access-request-page.gocardless-permissions-checklist.two"
                key="bank-data-access-request-page.gocardless-permissions-checklist.two"
              >
                View your sort code
              </Trans>
            ),
            <Trans
              id="bank-data-access-request-page.gocardless-permissions-checklist.three"
              key="bank-data-access-request-page.gocardless-permissions-checklist.three"
            >
              View your account number
            </Trans>,
            <Trans
              id="bank-data-access-request-page.gocardless-permissions-checklist.four"
              key="bank-data-access-request-page.gocardless-permissions-checklist.four"
            >
              View your available account balance
            </Trans>,
          ].filter(Boolean)}
        />

        <Space v={2} />

        <P weight={FontWeight.SemiBold} spaceBelow={1}>
          <Trans id="bank-data-access-request-page.creditor-permissions-checklist.title">
            {billingRequest.creditor_name} will be able to:
          </Trans>
        </P>

        <Checklist
          icon={
            <Avatar
              icon={Glyph.Tick}
              bg={Color.Greystone_1400}
              color={Color.White}
              size={AvatarSize.Xs}
            />
          }
          items={[
            [
              <Trans
                id="bank-data-access-request-page.creditor-permissions-checklist.one"
                key="bank-data-access-request-page.creditor-permissions-checklist.one"
              >
                View your name
              </Trans>,
            ],
          ]}
        />
      </>
    );
  },
};

const schemeCustomisations: Pick<
  { [scheme in Scheme]: CustomisationKey },
  Scheme
> = {
  [HomeSchemeEnum.FasterPayments]: {
    ...defaultConfig,
    "billing-request.show-locale-switcher": () => false,
    "billing-request.match-default-locale": () => false,
    "billing-request.bank-wait-page.body": ({
      billingRequest,
      onRetry,
      payerTheme,
    }) => (
      <>
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
        >
          <P textAlign={TextAlign.Center}>
            <WaitingText billingRequest={billingRequest} />
          </P>
        </Box>

        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
        >
          <BrandedButton
            textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
            variant={ButtonVariant.Inline}
            onClick={onRetry}
            css={{ boxShadow: "none !important" }}
          >
            <Trans id="bank-wait-page.retry-link.something-wrong">
              Something wrong? Click to try again.
            </Trans>
          </BrandedButton>
        </Box>
      </>
    ),
    "billing-request.bank-confirm.header": ({ bank_name, billingRequest }) => {
      if (billingRequest && isVRPFlow(billingRequest)) {
        return (
          <Trans id="confirm-details-page.header.permission-for-recurring-payment">
            We need your consent to set up this payment
          </Trans>
        );
      }
      return (
        <Trans id="confirm-details-page.header.connecting-to-bank">
          Connecting you to {bank_name}
        </Trans>
      );
    },
    "billing-request.bank-confirm.header-notification": ({
      billingRequest,
    }) => {
      if (billingRequest && isVRPFlow(billingRequest)) {
        return null;
      }
      return (
        <Trans id="confirm-details-page.header.notification">
          We&#39;ll now connect to your bank to authorise payment
        </Trans>
      );
    },
    "billing-request.bank-confirm.body": (params) => {
      if (params?.billingRequest && isVRPFlow(params?.billingRequest)) {
        return (
          <>
            <Space v={0.5} />
            <BankConfirmViewPaymentAgreementBody {...params} />
            <Space v={0.5} />
          </>
        );
      }
      return <BankConfirmViewDefaultBody {...params} />;
    },
    "billing-request.bank-select.header": ({ billingRequest }) => {
      if (isVRPFlow(billingRequest)) {
        return (
          <>
            <Text weight={FontWeight.SemiBold} size={6}>
              <Trans id="institution-selection.header.select-bank">
                Select your bank
              </Trans>
            </Text>
            <Space v={0.5} />
          </>
        );
      }

      if (isEligibleForIBPABExperiment(billingRequest)) {
        return (
          // TODO: Translations
          <>
            <Text weight={FontWeight.SemiBold} size={6}>
              Select your bank to continue
            </Text>
            <Space v={0.5} />
          </>
        );
      }
      return <InstitutionSelectionHeader />;
    },
    "billing-request.bank-select.payment-authorisation-notice": ({
      billingRequest,
    }) => {
      if (isEligibleForIBPABExperiment(billingRequest)) {
        return (
          // TODO: Translations
          <Box>
            You&apos;ll confirm this payment using your bank&apos;s mobile app.
            <Space v={1} />
          </Box>
        );
      }

      return (
        <Box>
          <Trans id="institution-selection.payment-authorisation-notice">
            You&apos;ll be asked to authorise this payment using your mobile
            banking app or online banking
          </Trans>
          <Space v={1} />
        </Box>
      );
    },
    "billing-request.bank-select.show-account-number-notice": ({
      billingRequest,
    }) => {
      return !isVRPFlow(billingRequest);
    },
    "billing-request.bank-confirm.payment-agreement.notice": ({
      billingRequest,
    }) => (
      <Trans id="bank-confirm.payment-agreement-details.recurring-payment-description">
        Our payment provider, GoCardless, needs your permission to set up a
        recurring payment using your{" "}
        {billingRequest?.resources?.customer_bank_account?.bank_name} account.
        This payment will be restricted to the parameters below. You can manage
        this payment agreement from your online banking, or by contacting{" "}
        {billingRequest?.creditor_name} at any time.
      </Trans>
    ),
    "billing-request.bank-connect.terms-and-conditions": ({
      billingRequest,
      whatIsGCButton,
      termsOfUseButton,
      privacyPolicyButton,
    }) => {
      if (isVRPFlow(billingRequest)) {
        return (
          <Trans id="modals.terms-and-conditions.recurring-declaration">
            By continuing you agree for our partner, GoCardless {whatIsGCButton}
            , to trigger recurring payments from your bank account based on the
            payment parameters you have agreed with us. You also agree to
            GoCardless&apos; {termsOfUseButton} and {privacyPolicyButton}.
          </Trans>
        );
      }
      return (
        <Trans id="modals.terms-and-conditions.declaration">
          By continuing you agree for our partner, GoCardless {whatIsGCButton},
          to trigger a one-off payment from your bank account. You also agree to
          GoCardless&apos; {termsOfUseButton}.
        </Trans>
      );
    },
    "billing-request.modal.payer-terms-of-use": ({ billingRequest }) => {
      if (isVRPFlow(billingRequest)) {
        return <PayerTermsOfUseForVRP />;
      } else {
        return <PayerTermsOfUseForUK />;
      }
    },
  },
  [HomeSchemeEnum.SepaCreditTransfer]: {
    ...defaultConfig,
    "billing-request.bank-wait-page.body": ({
      billingRequest,
      onRetry,
      payerTheme,
    }) => (
      <>
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
        >
          <P textAlign={TextAlign.Center}>
            <WaitingText billingRequest={billingRequest} />
          </P>
        </Box>
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
        >
          <BrandedButton
            textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
            variant={ButtonVariant.Inline}
            onClick={onRetry}
            css={{ boxShadow: "none !important" }}
          >
            <Trans id="bank-wait-page.retry-link.something-wrong">
              Something wrong? Click to try again.
            </Trans>
          </BrandedButton>
        </Box>
      </>
    ),
    "billing-request.bank-confirm.header": ({ bank_name }) => (
      <Trans id="confirm-details-page.header.connecting-to-bank">
        Connecting you to {bank_name}
      </Trans>
    ),
    "billing-request.bank-confirm.header-notification": () => (
      <Trans id="confirm-details-page.header.notification">
        We&#39;ll now connect to your bank to authorise payment
      </Trans>
    ),
    "billing-request.bank-confirm.bank-details.show-not-your-bank-link": () =>
      false,
    "billing-request.bank-connect.terms-and-conditions": ({
      whatIsGCButton,
      termsOfUseButton,
    }) => (
      <Trans id="modals.terms-and-conditions.declaration">
        By continuing you agree for our partner, GoCardless {whatIsGCButton}, to
        trigger a one-off payment from your bank account. You also agree to
        GoCardless&apos; {termsOfUseButton}.
      </Trans>
    ),
    "billing-request.modal.payer-terms-of-use": () => <PayerTermsOfUseForEU />,
  },
  [HomeSchemeEnum.SepaInstantCreditTransfer]: {
    ...defaultConfig,
    "billing-request.bank-wait-page.body": ({
      billingRequest,
      onRetry,
      payerTheme,
    }) => (
      <>
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
        >
          <P textAlign={TextAlign.Center}>
            <WaitingText billingRequest={billingRequest} />
          </P>
        </Box>
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
        >
          <BrandedButton
            textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
            variant={ButtonVariant.Inline}
            onClick={onRetry}
            css={{ boxShadow: "none !important" }}
          >
            <Trans id="bank-wait-page.retry-link.something-wrong">
              Something wrong? Click to try again.
            </Trans>
          </BrandedButton>
        </Box>
      </>
    ),
    "billing-request.bank-confirm.header": ({ bank_name }) => (
      <Trans id="confirm-details-page.header.connecting-to-bank">
        Connecting you to {bank_name}
      </Trans>
    ),
    "billing-request.bank-confirm.header-notification": () => (
      <Trans id="confirm-details-page.header.notification">
        We&#39;ll now connect to your bank to authorise payment
      </Trans>
    ),
    "billing-request.bank-confirm.show-fee-notice": () => true,
    "billing-request.bank-confirm.bank-details.show-not-your-bank-link": () =>
      false,
    "billing-request.bank-connect.terms-and-conditions": ({
      whatIsGCButton,
      termsOfUseButton,
    }) => (
      <Trans id="modals.terms-and-conditions.declaration">
        By continuing you agree for our partner, GoCardless {whatIsGCButton}, to
        trigger a one-off payment from your bank account. You also agree to
        GoCardless&apos; {termsOfUseButton}.
      </Trans>
    ),
    "billing-request.modal.payer-terms-of-use": () => <PayerTermsOfUseForEU />,
  },
  [HomeSchemeEnum.PayTo]: {
    ...defaultConfig,
    "billing-request.bank-wait-page.loading-icon": () => (
      <SpinnerWithImage imageSource={PayToLogo} altText="PayTo Logo" />
    ),
    "billing-request.bank-wait-page.body": ({
      billingRequest,
      shouldTakenToBankAuthCompletionView,
      bankAuthorisation,
    }) => {
      /* eslint-disable-next-line */
      const [locale] = useI18n();

      return (
        <>
          <Box
            layout="flex"
            flexDirection="column"
            alignItems={AlignItems.Center}
            justifyContent={JustifyContent.Center}
          >
            <Tag
              variant={TagVariant.Tinted}
              key={TagColor.Warning}
              colorScheme={ColorScheme.OnLight}
              color={TagColor.Warning}
              size={TagSize.Lg}
            >
              <Trans id="bank-wait-page.payto-agreement-needs-action">
                Action required
              </Trans>
            </Tag>

            <Space v={1} />
            <Box css={isMobile ? { padding: "0 24px" } : { padding: "0 64px" }}>
              <P textAlign={TextAlign.Center} size={4}>
                {shouldTakenToBankAuthCompletionView ? (
                  <Trans id="bank-wait-page.payto-agreement-needs-authorisation">
                    Your payment agreement has been created but needs your
                    authorisation
                  </Trans>
                ) : (
                  <Trans id="bank-wait-page.payto-agreement-created">
                    Your payment agreement has been created but still needs your
                    authorisation
                  </Trans>
                )}
              </P>
              <Space v={1.5} />
              <P textAlign={TextAlign.Center} weight={FontWeight.Bold} size={4}>
                <Trans id="bank-wait-page.authorise-payto">
                  Please open your mobile banking app or online banking to
                  authorise
                </Trans>
              </P>
            </Box>
            <Space v={2} />
            <Box
              borderWidth={1}
              borderRadius={2}
              css={{ padding: "32px", margin: "0 32px" }}
              borderColor={Color.Greystone_200}
            >
              <P textAlign={TextAlign.Center} size={2} weight={FontWeight.Bold}>
                <Trans id="bank-wait-page.requested-payto-agreement">
                  {`${billingRequest.creditor_name} has requested this payment
                  agreement is authorised by ${convertToLocaleDateString({
                    date: bankAuthorisation?.expires_at,
                    locale,
                    options: {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      timeZoneName: "short",
                    },
                  })}`}
                </Trans>
              </P>
              <Space v={1} />
              <P textAlign={TextAlign.Center} size={2}>
                {shouldTakenToBankAuthCompletionView ? (
                  <Trans id="bank-wait-page.return-back-payto-msg-if-authorisation-pending">
                    You&apos;ll receive an email confirmation after
                    authorisation
                  </Trans>
                ) : (
                  <Trans id="bank-wait-page.return-back-payto-msg">
                    After authorisation, please return to this page.
                  </Trans>
                )}
              </P>
            </Box>
            <Space v={1} />
          </Box>
        </>
      );
    },
    "billing-request.bank-confirm.header": () => (
      <Trans id="bank-confirm.header.pay-to">
        Please confirm your details to set up this PayTo agreement
      </Trans>
    ),
    "billing-request.bank-confirm.body": (params) => (
      <>
        <Space v={0.5} />
        <BankConfirmViewPaymentAgreementBody {...params} />
        <Space v={0.5} />
      </>
    ),
    "billing-request.bank-confirm.payment-agreement.notice": ({
      billingRequest,
    }) => (
      <Trans id="bank-confirm.payment-agreement-details.pay-to-description">
        Our payment provider, GoCardless, needs your permission to set up a
        PayTo agreement using your{" "}
        {billingRequest?.resources?.customer_bank_account?.bank_name || ""}{" "}
        account. This payment would be restricted to the parameters below. You
        will always be in control and can make changes to this payment from your
        online banking at any time.
      </Trans>
    ),
    "billing-request.bank-confirm.payment-agreement.editable-bank-account": ({
      bankAuthorisation,
    }) => {
      return isPaymentAgreementEditable(bankAuthorisation);
    },
    "billing-request.bank-confirm.payment-agreement.editable-customer-details":
      ({ bankAuthorisation }) => {
        return isPaymentAgreementEditable(bankAuthorisation);
      },
  },
  [HomeSchemeEnum.Ach]: {
    ...defaultConfig,
    "billing-request.bank-select.header": () => {
      return (
        <>
          <Text weight={FontWeight.SemiBold} size={6}>
            <Trans id="institution-selection.select-bank">
              Please select your bank
            </Trans>
          </Text>
          <Space v={0.5} />
          <Text size={2}>
            <Trans id="institution-selection.select-bank.description.ach">
              To set up your ACH Debit Authorization, we will securely connect
              you to your banking institution
            </Trans>
          </Text>
          <Space v={1} />
        </>
      );
    },
    "bank-data-access-request-page.request-account-info.title": () => {
      return (
        <P size={6} weight={FontWeight.SemiBold}>
          <Trans id="bank-data-access-request-page.request-account-info.title.ach">
            Connect your account
          </Trans>
        </P>
      );
    },
    "bank-data-access-request-page.request-account-info.description": ({
      billingRequest,
    }) => {
      return (
        <P>
          <Trans id="bank-data-access-request-page.request-account-info.description.ach">
            GoCardless would like to access your bank account information to
            verify your identity to set your ACH Debit authorization with{" "}
            {billingRequest.creditor_name}:
          </Trans>
        </P>
      );
    },

    "bank-data-access-request-page.request-account-info.checklist": () => {
      return (
        <>
          <Checklist
            icon={
              <Avatar
                icon={Glyph.Tick}
                bg={Color.Greystone_1400}
                color={Color.White}
                size={AvatarSize.Xs}
              />
            }
            items={[
              <Trans
                id="bank-data-access-request-page.gocardless-permissions-checklist.ach.one"
                key="bank-data-access-request-page.gocardless-permissions-checklist.ach.one"
              >
                Your name
              </Trans>,
              <Trans
                id="bank-data-access-request-page.gocardless-permissions-checklist.ach.two"
                key="bank-data-access-request-page.gocardless-permissions-checklist.ach.two"
              >
                Your address
              </Trans>,
              <Trans
                id="bank-data-access-request-page.gocardless-permissions-checklist.ach.three"
                key="bank-data-access-request-page.gocardless-permissions-checklist.ach.three"
              >
                Your bank account number and routing number
              </Trans>,
            ].filter(Boolean)}
          />
        </>
      );
    },
    "billing-request.bank-confirm.payment-agreement.editable-customer-details":
      ({ billingRequest, country_code }) => {
        return !isAchMxExperimentMandate(billingRequest, country_code);
      },
  },
  [HomeSchemeEnum.Autogiro]: {
    ...defaultConfig,
    "billing-request.collect-customer-details.corporate-payers-note": () => {
      return (
        <Banner
          leftAccessory={{
            type: BannerLeftAccessoryType.Status,
            status: BannerStatus.Info,
          }}
          variant={BannerVariant.Light}
          backgroundColor={Color.Greystone_100}
          css={{ filter: "none", borderRadius: "8px" }}
          layout={BannerLayout.Horizontal}
        >
          <P color={ColorPreset.TextOnLight_02} size={2}>
            <Trans id="autogiro-bankid.company-authorisation-note">
              <Text weight={FontWeight.SemiBold}>As an organisation,</Text> this
              mandate will need to be authorised by a company representative
              with access to the BankID app.
            </Trans>
          </P>
        </Banner>
      );
    },
    "billing-request.bank-wait-page.loading-icon": () => (
      <SpinnerWithImage imageSource={BankIDLogo} altText="BankID Logo" />
    ),
    "billing-request.bank-connect.qr-code-scanner.header": () => {
      return (
        <Trans id="autogiro-bankid.connect.title">Identify with BankID</Trans>
      );
    },
    "billing-request.bank-connect.qr-code-scanner.description": () => {
      return (
        <Trans id="autogiro-bankid.connect.desktop.qr-code-description">
          Scan the QR code within your BankID app
        </Trans>
      );
    },
    "billing-request.bank-connect.qr-code-scanner.show-learn-more": () => false,
    "billing-request.bank-connect.qr-code-scanner.needs-mobile-interstitial":
      () => false,
  },
  [HomeSchemeEnum.Bacs]: {
    ...defaultConfig,
    "billing-request.show-locale-switcher": () => false,
    "billing-request.match-default-locale": () => false,
    "billing-request.bank-select.payment-authorisation-notice": () => {
      return (
        <Box>
          <Trans id="institution-selection.payment-authorisation-notice">
            You&apos;ll be asked to authorise this payment using your mobile
            banking app or online banking
          </Trans>
          <Space v={1} />
        </Box>
      );
    },
  },
  [HomeSchemeEnum.Becs]: {
    ...defaultConfig,
  },
  [HomeSchemeEnum.Betalingsservice]: {
    ...defaultConfig,
  },
  [HomeSchemeEnum.BecsNz]: {
    ...defaultConfig,
  },
  [HomeSchemeEnum.Pad]: {
    ...defaultConfig,
  },
  [AvailableDebitSchemeEnum.SepaCore]: {
    ...defaultConfig,
  },
};

const WaitingText = ({
  billingRequest,
}: {
  billingRequest: BillingRequestResource;
}) => {
  switch (getBankAuthorisationAdapter(billingRequest)) {
    case Adapter.OpenBankingGatewayAis:
      return (
        <Trans id="bank-wait-page.authorise-direct-debit">
          Please authorise the Direct Debit through your bank
        </Trans>
      );
    case Adapter.BankidAis:
      return (
        <Text preset={TypePreset.Heading_04}>
          <Trans id="autogiro-bankid.connect.loading">
            Please identify in your BankID app
          </Trans>
        </Text>
      );
    case Adapter.BankPayRecurring: {
      const bank_name = billingRequest.resources?.institution?.name;
      return (
        <Trans id="confirm-details-page.header.connecting-to-bank">
          Connecting you to {bank_name}
        </Trans>
      );
    }
    default:
      return (
        <Trans id="bank-wait-page.authorise-payment">
          Please authorise the payment through your bank
        </Trans>
      );
  }
};

export const customiseForScheme = ({
  scheme,
  key,
  params,
}: {
  scheme: Scheme;
  key: keyof CustomisationKey;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
}) => {
  const customise = schemeCustomisations?.[scheme]?.[key];
  return customise && customise(params);
};
