import {
  AvailableDebitSchemeEnum,
  BillingRequestResource,
} from "@gocardless/api/dashboard/types";
import {
  FontWeight,
  HoverEffect,
  PlainLink,
  TypePreset,
} from "@gocardless/flux-react";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import { TrackingEvents } from "src/common/trackingEvents";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import { PayerThemeType } from "src/state";

const ViewESignTerms = ({
  payerTheme,
  eSignTermsLink,
  sendEvent,
  billingRequest,
}: {
  billingRequest: BillingRequestResource;
  eSignTermsLink: string;
  payerTheme?: PayerThemeType;
  sendEvent: (name: string, params?: {}) => void;
}) => {
  return (
    <PlainLink
      id="eSignterms"
      textDecoration="underline"
      effect={HoverEffect.TextDecoration}
      preset={TypePreset.Body_01}
      weight={FontWeight.Bold}
      css={{ color: getBrandColorFor(BrandedComponentType.Link, payerTheme) }}
      href={eSignTermsLink}
      target="_blank"
      onClick={() =>
        sendEvent(TrackingEvents.CONFIRMATION_STEP_ESIGN_TERMS_VIEWED)
      }
    >
      {translateForScheme({
        scheme: billingRequest?.mandate_request
          ?.scheme as AvailableDebitSchemeEnum,
        translationKey:
          "confirm-details-page.form.view-gocardless-e-sign-terms",
      })}
    </PlainLink>
  );
};

export default ViewESignTerms;
