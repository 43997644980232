import { Box, DT, FontWeight, DD, AlignItems } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

export const BankAccountFields = ({
  isACH,
  accountNumberEnding,
  bankName,
}: {
  isACH: boolean;
  accountNumberEnding?: string;
  bankName?: string;
}) => {
  return isACH ? (
    <Box>
      <DT size={[2, null, 3]} weight={FontWeight.Normal}>
        <Trans>Bank account ending with ******{accountNumberEnding}</Trans>
      </DT>
      <DD size={[2, null, 3]} weight={FontWeight.SemiBold}>
        <Box layout="flex" alignItems={AlignItems.Center}>
          <Trans>{bankName}</Trans>
        </Box>
      </DD>
    </Box>
  ) : (
    <Box>
      <DT size={[2, null, 3]} weight={FontWeight.Normal}>
        <Trans>Account number</Trans>
      </DT>
      <DD size={[2, null, 3]} weight={FontWeight.SemiBold}>
        <Box layout="flex" alignItems={AlignItems.Center}>
          <Trans>Ending ******{accountNumberEnding}</Trans>
        </Box>
      </DD>
    </Box>
  );
};
