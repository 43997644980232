import {
  BillingRequestsMandateRequest,
  ConsentType,
} from "@gocardless/api/dashboard/types";
import { Space } from "@gocardless/flux-react";
import { useState } from "react";
import { ExpandedStates } from "src/common/states";
import { ConsentType as ConsentDisplay } from "src/components/shared/BillingRequestDescription/AuthorisationDetails/ConsentType";
import { ToggleDetailsButton } from "src/components/shared/BillingRequestDescription/AuthorisationDetails/ToggleDetailsButton";
import { SchemesWithConsentParameters } from "src/components/shared/BillingRequestDescription/AuthorisationDetails/DisplayedConsentInformation";

interface AuthorisationDetailsProps {
  mandateRequest?: BillingRequestsMandateRequest;
}

export const AuthorisationDetails = ({
  mandateRequest,
}: AuthorisationDetailsProps) => {
  const [expandedState, setExpandedState] = useState(ExpandedStates.Collapsed);

  const consentType = mandateRequest?.consent_type;
  const scheme = mandateRequest?.scheme as SchemesWithConsentParameters;
  if (!consentType || !scheme) {
    return null;
  }

  const setNextState = () => {
    setExpandedState((pendingState) =>
      pendingState === ExpandedStates.Expanded
        ? ExpandedStates.Collapsed
        : ExpandedStates.Expanded
    );
  };

  const showDetails = expandedState === ExpandedStates.Expanded;

  return (
    <>
      {showDetails && (
        <>
          <ConsentDisplay
            consentType={consentType as ConsentType}
            scheme={scheme}
          />
          {/* Constraint parameters go here*/}
        </>
      )}
      <Space v={1} />
      <ToggleDetailsButton
        setNextState={setNextState}
        currentState={expandedState}
        showDetails={showDetails}
      />
    </>
  );
};
