import {
  Scheme,
  SchemesWithConsentParameters,
  SchemesWithConsentParametersMap,
} from "src/common/scheme";
import { ConsentType } from "@gocardless/api/dashboard/types";

import { DisplayedConsentInformation } from "./DisplayedConsentInformation";

const schemeHasConsentType = (
  scheme?: Scheme | SchemesWithConsentParameters
): scheme is SchemesWithConsentParameters => {
  if (!scheme) {
    return false;
  }
  const schemesWithConsentType = Object.values(
    SchemesWithConsentParametersMap
  ).map((value) => value.toString());

  return schemesWithConsentType.includes(scheme.toString());
};

export const getDisplayedConsentInfo = (
  scheme?: Scheme,
  consentType?: ConsentType
) => {
  if (schemeHasConsentType(scheme) && consentType) {
    return DisplayedConsentInformation[scheme][consentType];
  }

  return {
    instalmentConsentTypeText: undefined,
    consentTypeContentText: undefined,
    consentTypeText: undefined,
    toolTipContent: undefined,
  };
};
