import { Trans } from "@lingui/macro";
import {
  P,
  PProps,
  Space,
  Text,
  Interpose,
  FontWeight,
  Box,
  Icon,
  ButtonVariant,
  Glyph,
  HoverEffect,
  PlainLink,
  Tooltip,
  ColorPreset,
} from "@gocardless/flux-react";
import { Image } from "src/components/shared/Image";
import { ModalNames } from "src/components/shared/Modals";
import BrandedLink from "src/components/shared/BrandedComponents/BrandedLink";
import { Scheme } from "src/common/scheme";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import { getTranslatedLink, Link } from "src/common/links";
import { CSSForLinkAppearance } from "src/components/shared/BrandedComponents";
import { I18n } from "@lingui/core";
import { PayerThemeType } from "src/state";
import { ConsentType } from "@gocardless/api/dashboard/types";

import { TrackingEvents } from "../trackingEvents";

import { DisplayedConsentInformation } from "./DisplayedConsentInformation/DisplayedConsentInformation";

const PWithSpacing = (props: PProps) => <P spaceBelow={1} {...props} />;

const DD_LOGO_HEIGHT = 20;
const DD_LOGO_WIDTH = 61;
const DD_LOGO_VIEWBOX = "0 0 82 27";

const REST_BANK_LOGO_HEIGHT = 16;
const REST_BANK_LOGO_WIDTH = 21;
const REST_BANK_LOGO_VIEWBOX = "0 0 23 18";

const SEPA_LOGO_HEIGHT = 20;
const SEPA_LOGO_WIDTH = 68;
const SEPA_LOGO_VIEWBOX = "0 0 59 16";

// All possible translation keys must be declared here
// We're assuming that all schemes must have all the keys defined, which
// makes it type-safe. We can consider adding optional keys with a risk
export type TranslationKey =
  | "billing-request-description-info"
  | "billing-request-description-info.restricted-direct-debit-mandate"
  | "billing-request-description-dual-flow-mandate-heading"
  | "billing-request-description-info-scheme-logo"
  | "billing-request.heading"
  | "billing-request.heading.dual-flow"
  | "billing-request.footer.gocardless-registration-information"
  | "billing-request.footer.direct-debit-guarantee"
  | "confirm-details-page.instruction-title"
  | "confirm-details-page.introduction"
  | "confirm-details-page.creditor-name"
  | "confirm-details-page.creditor-reference"
  | "confirm-details-page.creditor-address"
  | "confirm-details-page.instruction-type"
  | "confirm-details-page.instruction-type-recurrent"
  | "confirm-details-page.instruction-reference"
  | "confirm-details-page.instruction-reference-after-confirmation"
  | "confirm-details-page.form.confirm-button"
  | "confirm-details-page.date"
  | "confirm-details-page.footer.legal-notice"
  | "confirm-details-page.form.view-direct-debit-instructions"
  | "confirm-details-page.form.view-gocardless-e-sign-terms"
  | "collect-bank-account-page.bank-details-panel.heading"
  | "collect-bank-account-page.bank-details-panel.logo"
  | "success-page.header"
  | "success-page.bank-statement-warning"
  | "confirm-details-page.bank-name"
  | "confirm-details-page.checkbox-panel.info"
  | "bank-data-access-request-page.ais-notice";

type SchemeTranslationsType = {
  [key in Scheme]: {
    [translationKey in TranslationKey]: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params: any
    ) => React.ReactElement | null;
  };
};

const billingRequestCFSBLegalFooter = ({
  payerTheme,
  isDropinMode,
  i18n,
  creditorName,
}: {
  payerTheme: PayerThemeType;
  isDropinMode: boolean;
  i18n: I18n;
  creditorName: string;
}) => {
  const brandColor = getBrandColorFor(
    BrandedComponentType.FooterLink,
    payerTheme,
    isDropinMode
  );
  return (
    <Interpose node={<Space v={1} />}>
      <Trans>
        GoCardless Inc.&apos;s (a Delaware Corporation, NMLS ID 2123932) payment
        service is provided by (i) a program sponsored by Community Federal
        Savings Bank (“CFSB”), or (ii) GoCardless Inc., a FinCEN registered
        money services business, registration number 31000261158426. Where your
        payment is processed by GoCardless Inc., it serves as agent to receive
        payment on {creditorName}
        &apos;s behalf and your payment to GoCardless Inc. constitutes payment
        to {creditorName}.
      </Trans>
      <Trans>
        GoCardless and CFSB use personal data as described in{" "}
        <PlainLink
          effect={HoverEffect.TextDecoration}
          textDecoration="underline"
          href={getTranslatedLink(Link.CFSBPrivacy, i18n)}
          target="_blank"
          css={brandColor && { ...CSSForLinkAppearance(brandColor) }}
        >
          these privacy notices.
        </PlainLink>
      </Trans>
    </Interpose>
  );
};

export const SchemeTranslations: SchemeTranslationsType = {
  [Scheme.Ach]: {
    "billing-request.heading.dual-flow": ({
      creditorName,
      consentType,
    }: {
      creditorName: string;
      consentType: ConsentType;
    }) => {
      if (consentType === ConsentType.Standing) {
        return (
          <>
            <Text weight={FontWeight.Bold}>
              Set up a standing ACH Debit Authorization with
            </Text>{" "}
            {creditorName}
          </>
        );
      }
      return (
        <Trans id="billing-request.heading.dual-flow">
          <Text weight={600}>Set up your payment to </Text>
          {creditorName}
        </Trans>
      );
    },
    "billing-request.heading": ({
      creditorName,
      consentType,
    }: {
      creditorName: string;
      consentType?: ConsentType;
    }) => {
      if (!consentType) {
        return (
          <Trans id="billing-request.heading.ach">
            <Text weight={FontWeight.SemiBold}>
              Set up an ACH Debit Authorization with{" "}
            </Text>
            <Text weight={FontWeight.Normal}>{creditorName}</Text>
          </Trans>
        );
      }
      const { consentTypeContentText } =
        DisplayedConsentInformation[Scheme.Ach][consentType];
      // TODO: Refactor when translations are added to reduce repetition
      return (
        <>
          <Text weight={FontWeight.SemiBold}>
            Set up a {consentTypeContentText} with{" "}
          </Text>
          <Text weight={FontWeight.Normal}>{creditorName}</Text>
        </>
      );
    },
    "billing-request.footer.direct-debit-guarantee": () => null,
    "billing-request.footer.gocardless-registration-information":
      billingRequestCFSBLegalFooter,
    "confirm-details-page.instruction-title": () => null,
    "confirm-details-page.introduction": ({ creditorName, merchantEmail }) => (
      <Trans>
        <Interpose node={<Space v={1} />}>
          <Text>
            Please check the details and copy of your ACH Debit Authorization
            provided below are correct. Details of your payments will be
            provided via email notification.
          </Text>
          <Text>
            You can revoke your ACH Debit Authorization by emailing{" "}
            {creditorName} at {merchantEmail}. This will be revoked in
            accordance with {creditorName}&apos;s timings, and scheduled debits
            due during this time may still be honored.
          </Text>
        </Interpose>
      </Trans>
    ),
    "confirm-details-page.creditor-name": () => null,
    "confirm-details-page.creditor-reference": () => null,
    "confirm-details-page.creditor-address": () => null,
    "confirm-details-page.instruction-type": () => null,
    "confirm-details-page.instruction-reference": () => null,
    "confirm-details-page.instruction-type-recurrent": () => null,
    "confirm-details-page.instruction-reference-after-confirmation": () => null,
    "confirm-details-page.date": () => null,
    "confirm-details-page.footer.legal-notice": () => null,
    "confirm-details-page.form.confirm-button": () => (
      <Trans id="confirm-details-page.confirm-button.ach">
        Confirm this ACH Debit Authorization
      </Trans>
    ),
    "confirm-details-page.form.view-gocardless-e-sign-terms": () => (
      <Trans id="confirm-details-page.view-gocardless-e-sign-terms.ach">
        View GoCardless&apos; E-Sign terms
      </Trans>
    ),
    "confirm-details-page.form.view-direct-debit-instructions": () => (
      <Trans id="confirm-details-page.form.view-direct-debit-instructions.ach">
        View your ACH Debit Authorization
      </Trans>
    ),
    "confirm-details-page.bank-name": () => (
      <Trans id="confirm-details-page.bank-name.ach">Bank name</Trans>
    ),
    "confirm-details-page.checkbox-panel.info": () => null,
    "collect-bank-account-page.bank-details-panel.heading": () => (
      <Trans id="collect-bank-account-page.bank-details-panel.heading.ach">
        Payments are securely powered by GoCardless
      </Trans>
    ),
    "billing-request-description-info-scheme-logo": () => (
      <Image
        name="BankLogo"
        alt="direct debit"
        data-testid="direct debit"
        width={24}
        height={20}
        viewBox="0 0 24 20"
      />
    ),
    "collect-bank-account-page.bank-details-panel.logo": () => (
      <Image
        name="BankLogo"
        alt="direct debit"
        data-testid="direct debit"
        width={24}
        height={20}
        viewBox="0 0 24 20"
      />
    ),
    "success-page.header": ({
      creditor_name,
      is_verified_mandate,
    }: {
      creditor_name: string;
      is_verified_mandate: boolean;
    }) => (
      <>
        {is_verified_mandate && (
          <P>
            <Trans id="success-page.header.ach.vm">
              We have successfully verified your identity using Verified
              Mandates.
            </Trans>
          </P>
        )}
        <Trans id="success-page.header.ach">
          Your ACH Debit Authorization with {creditor_name} was set up
          successfully.
        </Trans>
      </>
    ),
    "success-page.bank-statement-warning": ({ sunBankStatementName }) => (
      <Trans id="success-page.bank-statement-warning.ach">
        <strong>{sunBankStatementName}</strong> will appear on your bank
        statement when payments are taken against this ACH Debit Authorization.
      </Trans>
    ),
    "billing-request-description-info": ({
      creditor_name,
      consentType,
    }: {
      creditor_name: string;
      consentType?: ConsentType;
    }) => {
      const expectedConsentTypes = [
        ConsentType.Recurring,
        ConsentType.OneOff,
        ConsentType.Standing,
      ];
      const showGenericDefault =
        !consentType || !expectedConsentTypes.includes(consentType);

      if (showGenericDefault) {
        return (
          <Trans id="billing-request-description-info.ach">
            Your ACH Debit Authorization will be set up now in accordance with
            the amount and timings agreed with {creditor_name}.
          </Trans>
        );
      }

      const { toolTipContent, consentTypeContentText } =
        DisplayedConsentInformation[Scheme.Ach][consentType];

      const endingText =
        consentType === ConsentType.Standing ? (
          <>
            , and future debits will be initiated following you providing your
            affirmative action to{" "}
            <Text weight={FontWeight.Bold}>{creditor_name}&apos;s</Text>, which
            may include but is not limited to a text message, phone call,
            internet transaction, or purchase of{" "}
            <Text weight={FontWeight.Bold}>{creditor_name}&apos;s</Text> goods
            or services
          </>
        ) : (
          <>
            . As soon as <Text weight={FontWeight.Bold}>{creditor_name}</Text>{" "}
            sets up your payment, we&apos;ll send you an email to confirm the
            payment details.
          </>
        );

      return (
        // TODO: Add translations
        <>
          Your
          <Box layout="inline-block" spaceBefore={0.25} spaceAfter={0.25}>
            <Tooltip message={toolTipContent}>
              {(triggerProps) => {
                return (
                  <Text
                    {...triggerProps}
                    size={1}
                    decoration="underline"
                    color={ColorPreset.TextOnLight_01}
                    weight={FontWeight.Normal}
                  >
                    {consentTypeContentText}
                  </Text>
                );
              }}
            </Tooltip>
          </Box>
          will be set up now{endingText}
        </>
      );
    },
    "billing-request-description-info.restricted-direct-debit-mandate": ({
      creditor_name,
    }: {
      creditor_name: string;
    }) => (
      <Trans id="billing-request-description-info.restricted-direct-debit-mandate.ach">
        This is a restricted ACH Debit Authorization, so you’re in control. When{" "}
        {creditor_name} wants to charge you we’ll ask for your approval first.
      </Trans>
    ),
    "billing-request-description-dual-flow-mandate-heading": () => (
      <Trans id="billing-request-description-dual-flow-mandate-heading.ach">
        ACH Debit Authorization setup
      </Trans>
    ),
    "bank-data-access-request-page.ais-notice": () => null,
  },

  [Scheme.Autogiro]: {
    "billing-request.heading.dual-flow": ({
      creditorName,
    }: {
      creditorName: string;
    }) => {
      return (
        <Trans id="billing-request.heading.dual-flow">
          <Text weight={600}>Set up your payment to </Text>
          <Space v={0} />
          {creditorName}
        </Trans>
      );
    },
    "billing-request.heading": ({ creditorName }) => (
      <Trans id="billing-request.heading.autogiro">
        <Text weight={FontWeight.SemiBold}>Set up a Direct Debit with </Text>
        <Text weight={FontWeight.Normal}>{creditorName}</Text>
      </Trans>
    ),
    "billing-request.footer.direct-debit-guarantee": () => null,
    "billing-request.footer.gocardless-registration-information": ({
      payerTheme,
      isDropinMode,
    }) => (
      <Trans>
        <P>
          GoCardless SAS (company registration number 834 422 180, R.C.S. PARIS)
          is authorised by the ACPR (French Prudential Supervision and
          Resolution Authority), Bank Code (CIB) 17118, for the provision of
          payment services.
        </P>

        <P>
          GoCardless uses personal data as described in our{" "}
          <BrandedLink
            textColor={getBrandColorFor(
              BrandedComponentType.FooterLink,
              payerTheme,
              isDropinMode
            )}
            variant={ButtonVariant.Inline}
            href="https://gocardless.com/privacy/"
            target="_blank"
          >
            Privacy Notice.
          </BrandedLink>
        </P>
      </Trans>
    ),
    "confirm-details-page.form.confirm-button": () => (
      <Trans id="confirm-details-page.confirm-button.autogiro">
        Set up this Direct Debit
      </Trans>
    ),
    "confirm-details-page.form.view-direct-debit-instructions": () => (
      <Trans id="confirm-details-page.form.view-direct-debit-instructions.autogiro">
        View your Direct Debit instruction
      </Trans>
    ),
    "confirm-details-page.form.view-gocardless-e-sign-terms": () => null,
    "confirm-details-page.instruction-title": () => null,
    "confirm-details-page.introduction": () => null,
    "confirm-details-page.creditor-name": () => null,
    "confirm-details-page.creditor-reference": () => null,
    "confirm-details-page.creditor-address": () => null,
    "confirm-details-page.instruction-type": () => null,
    "confirm-details-page.instruction-reference": () => null,
    "confirm-details-page.instruction-type-recurrent": () => null,
    "confirm-details-page.instruction-reference-after-confirmation": () => null,
    "confirm-details-page.date": () => null,
    "confirm-details-page.bank-name": () => null,
    "confirm-details-page.checkbox-panel.info": () => null,
    "confirm-details-page.footer.legal-notice": ({ sunBankStatementName }) => (
      <Trans id="confirm-details-page.footer.legal-notice.autogiro">
        {sunBankStatementName} will appear on your bank statement.
      </Trans>
    ),
    "collect-bank-account-page.bank-details-panel.heading": () => (
      <Trans id="collect-bank-account-page.bank-details-panel.heading.autogiro">
        Payments are securely powered by GoCardless
      </Trans>
    ),
    "collect-bank-account-page.bank-details-panel.logo": () => (
      <Image
        name="BankLogo"
        alt="direct debit"
        width={REST_BANK_LOGO_WIDTH}
        height={REST_BANK_LOGO_HEIGHT}
        viewBox={REST_BANK_LOGO_VIEWBOX}
      />
    ),
    "billing-request-description-info-scheme-logo": () => null,
    "success-page.header": ({ creditor_name }: { creditor_name: string }) => (
      <Trans id="success-page.header.autogiro">
        Your Direct Debit with {creditor_name} was set up successfully.
      </Trans>
    ),
    "success-page.bank-statement-warning": ({ sunBankStatementName }) => (
      <Trans id="success-page.bank-statement-warning.autogiro">
        <strong>{sunBankStatementName}</strong> will appear on your bank
        statement when payments are taken against this Direct Debit.
      </Trans>
    ),
    "billing-request-description-info": () => (
      <Trans>
        Your Direct Debit will be set up now. We&apos;ll confirm the amount and
        let you know before future payments are taken.
      </Trans>
    ),
    "billing-request-description-info.restricted-direct-debit-mandate": ({
      creditor_name,
    }: {
      creditor_name: string;
    }) => (
      <Trans id="billing-request-description-info.restricted-direct-debit-mandate.autogiro">
        This is a restricted Direct Debit, so you’re in control. When{" "}
        {creditor_name} wants to charge you we’ll ask for your approval first.
      </Trans>
    ),
    "billing-request-description-dual-flow-mandate-heading": () => (
      <Trans id="billing-request-description-dual-flow-mandate-heading.autogiro">
        Direct Debit setup
      </Trans>
    ),
    "bank-data-access-request-page.ais-notice": () => null,
  },

  [Scheme.Bacs]: {
    "billing-request.heading.dual-flow": ({
      creditorName,
    }: {
      creditorName: string;
    }) => {
      return (
        <Trans id="billing-request.heading.dual-flow">
          <Text weight={600}>Set up your payment to </Text>
          <Space v={0} />
          {creditorName}
        </Trans>
      );
    },
    "billing-request.heading": ({ creditorName }) => (
      <Trans id="billing-request.heading.bacs">
        <Text weight={FontWeight.SemiBold}>Set up a Direct Debit with </Text>
        <Text weight={FontWeight.Normal}>{creditorName}</Text>
      </Trans>
    ),
    "billing-request.footer.direct-debit-guarantee": ({
      payerTheme,
      isDropinMode,
      setModal,
      sendEvent,
    }) => (
      <Trans id="enter-details-page.footer.direct-debit-guarantee.bacs">
        Your payments are protected by the{" "}
        <BrandedLink
          textColor={getBrandColorFor(
            BrandedComponentType.FooterLink,
            payerTheme,
            isDropinMode
          )}
          variant={ButtonVariant.Inline}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            sendEvent(TrackingEvents.FOOTER_DIRECT_DEBIT_GUARANTEE_CLICKED);
            setModal(ModalNames.DirectDebit);
          }}
        >
          Direct Debit Guarantee
        </BrandedLink>
        .
      </Trans>
    ),
    "billing-request.footer.gocardless-registration-information": ({
      payerTheme,
      isDropinMode,
    }) => (
      <Trans>
        <P>
          GoCardless Ltd (company registration number 07495895) is authorised by
          the Financial Conduct Authority under the Payment Services Regulations
          2017, registration number 597190, for the provision of payment
          services.
        </P>

        <P>
          GoCardless uses personal data as described in our{" "}
          <BrandedLink
            textColor={getBrandColorFor(
              BrandedComponentType.FooterLink,
              payerTheme,
              isDropinMode
            )}
            variant={ButtonVariant.Inline}
            href="https://gocardless.com/privacy/"
            target="_blank"
          >
            Privacy Notice.
          </BrandedLink>
        </P>
      </Trans>
    ),
    "confirm-details-page.instruction-title": () => null,
    "confirm-details-page.introduction": () => null,
    "confirm-details-page.creditor-name": () => null,
    "confirm-details-page.creditor-reference": () => null,
    "confirm-details-page.creditor-address": () => null,
    "confirm-details-page.instruction-type": () => null,
    "confirm-details-page.instruction-reference": () => null,
    "confirm-details-page.instruction-type-recurrent": () => null,
    "confirm-details-page.instruction-reference-after-confirmation": () => null,
    "confirm-details-page.date": () => null,
    "confirm-details-page.bank-name": () => null,
    "confirm-details-page.checkbox-panel.info": () => (
      <Trans id="confirm-details-page.checkbox-panel.info">
        This usually only applies to company bank accounts. If more than one
        person is required to authorise Direct Debits on this account, we will
        need all signatories to sign a paper version of the Direct Debit
        mandate. We’ll give you instructions on how to do this next.
      </Trans>
    ),
    "confirm-details-page.footer.legal-notice": ({ sunBankStatementName }) => (
      <Trans id="confirm-details-page.footer.legal-notice.bacs">
        {sunBankStatementName} will appear on your bank statement. You may
        cancel this Direct Debit at any time by contacting{" "}
        {sunBankStatementName} or your bank.
      </Trans>
    ),
    "confirm-details-page.form.confirm-button": () => (
      <Trans id="confirm-details-page.confirm-button.bacs">
        Set up this Direct Debit
      </Trans>
    ),
    "confirm-details-page.form.view-direct-debit-instructions": () => (
      <Trans id="confirm-details-page.form.view-direct-debit-instructions.bacs">
        View your Direct Debit instruction
      </Trans>
    ),
    "confirm-details-page.form.view-gocardless-e-sign-terms": () => null,
    "collect-bank-account-page.bank-details-panel.heading": () => (
      <Trans id="collect-bank-account-page.bank-details-panel.heading.bacs">
        Payments are protected by the Direct Debit Guarantee
      </Trans>
    ),
    "collect-bank-account-page.bank-details-panel.logo": () => (
      <Image
        name="DDLogo"
        alt="direct debit"
        width={DD_LOGO_WIDTH}
        height={DD_LOGO_HEIGHT}
        viewBox={DD_LOGO_VIEWBOX}
        data-testid="DDLogo"
      />
    ),
    "billing-request-description-info-scheme-logo": () => (
      <Image
        name="DDLogo"
        alt="direct debit"
        width={DD_LOGO_WIDTH}
        height={DD_LOGO_HEIGHT}
        viewBox={DD_LOGO_VIEWBOX}
        data-testid="DDLogo"
      />
    ),
    "success-page.header": ({ creditor_name }: { creditor_name: string }) => (
      <Trans id="success-page.header.bacs">
        Your Direct Debit with {creditor_name} was set up successfully
      </Trans>
    ),
    "success-page.bank-statement-warning": ({ sunBankStatementName }) => (
      <Trans id="success-page.bank-statement-warning.bacs">
        <strong>{sunBankStatementName}</strong> will appear on your bank
        statement when payments are taken against this Direct Debit.
      </Trans>
    ),
    "billing-request-description-info": () => (
      <Trans>
        Your Direct Debit will be set up now. We&apos;ll confirm the amount and
        let you know before future payments are taken.
      </Trans>
    ),
    "billing-request-description-info.restricted-direct-debit-mandate": ({
      creditor_name,
    }: {
      creditor_name: string;
    }) => (
      <Trans id="billing-request-description-info.restricted-direct-debit-mandate.bacs">
        This is a restricted Direct Debit, so you’re in control. When{" "}
        {creditor_name} wants to charge you we’ll ask for your approval first.
      </Trans>
    ),
    "billing-request-description-dual-flow-mandate-heading": () => (
      <Trans id="billing-request-description-dual-flow-mandate-heading.bacs">
        Direct Debit setup
      </Trans>
    ),
    "bank-data-access-request-page.ais-notice": ({
      authority,
    }: {
      authority: string;
    }) => (
      <P size={2} data-testid="ais-notice">
        <Trans id="bank-data-access-request-page.ais-notice">
          *GoCardless will use its {authority} Account Information Service
          permissions to do this, and use the information to set up your direct
          debit more securely.
        </Trans>
      </P>
    ),
  },

  [Scheme.FasterPayments]: {
    "billing-request.heading.dual-flow": ({
      creditorName,
    }: {
      creditorName: string;
    }) => {
      return (
        <Trans id="billing-request.heading.dual-flow">
          <Text weight={600}>Set up your payment to </Text>
          <Space v={0} />
          {creditorName}
        </Trans>
      );
    },
    "billing-request.heading": ({ creditorName }) => (
      <Trans id="billing-request.heading.variable-recurring-payment">
        <Text weight={FontWeight.SemiBold}>Set up your payment to </Text>
        <Text weight={FontWeight.Normal}>{creditorName}</Text>
      </Trans>
    ),
    "billing-request.footer.direct-debit-guarantee": () => null,
    "billing-request.footer.gocardless-registration-information": ({
      payerTheme,
      isDropinMode,
    }) => (
      <Trans>
        <P>
          GoCardless Ltd (company registration number 07495895) is authorised by
          the Financial Conduct Authority under the Payment Services Regulations
          2017, registration number 597190, for the provision of payment
          services.
        </P>

        <P>
          GoCardless uses personal data as described in our{" "}
          <BrandedLink
            textColor={getBrandColorFor(
              BrandedComponentType.FooterLink,
              payerTheme,
              isDropinMode
            )}
            variant={ButtonVariant.Inline}
            href="https://gocardless.com/privacy/"
            target="_blank"
          >
            Privacy Notice.
          </BrandedLink>
        </P>
      </Trans>
    ),
    "confirm-details-page.instruction-title": () => null,
    "confirm-details-page.form.confirm-button": () => null,
    "confirm-details-page.form.view-direct-debit-instructions": () => null,
    "confirm-details-page.form.view-gocardless-e-sign-terms": () => null,
    "confirm-details-page.introduction": () => null,
    "confirm-details-page.creditor-name": () => null,
    "confirm-details-page.creditor-reference": () => null,
    "confirm-details-page.creditor-address": () => null,
    "confirm-details-page.instruction-type": () => null,
    "confirm-details-page.instruction-reference": () => null,
    "confirm-details-page.instruction-type-recurrent": () => null,
    "confirm-details-page.instruction-reference-after-confirmation": () => null,
    "confirm-details-page.date": () => null,
    "confirm-details-page.bank-name": () => null,
    "confirm-details-page.checkbox-panel.info": () => null,
    "confirm-details-page.footer.legal-notice": () => null,
    "collect-bank-account-page.bank-details-panel.heading": () => (
      <Box layout="flex">
        <Icon name={Glyph.PrivacyShield} size="20px" />
        <Space layout="inline" h={0.75} />
        <Trans id="collect-bank-account-page.payment-panel.heading">
          Your payment is securely powered by GoCardless
        </Trans>
      </Box>
    ),
    "collect-bank-account-page.bank-details-panel.logo": () => null,
    "billing-request-description-info-scheme-logo": () => null,
    "success-page.header": () => (
      <Trans id="success-page.header.payment-agreement">
        Your payment agreement was set up successfully
      </Trans>
    ),
    "success-page.bank-statement-warning": () => null,
    "billing-request-description-info": () => null,
    "billing-request-description-info.restricted-direct-debit-mandate": () =>
      null,
    "billing-request-description-dual-flow-mandate-heading": () => null,
    "bank-data-access-request-page.ais-notice": () => null,
  },

  [Scheme.Becs]: {
    "billing-request.heading.dual-flow": ({
      creditorName,
    }: {
      creditorName: string;
    }) => {
      return (
        <Trans id="billing-request.heading.dual-flow">
          <Text weight={600}>Set up your payment to </Text>
          <Space v={0} />
          {creditorName}
        </Trans>
      );
    },
    "billing-request.heading": ({ creditorName }) => (
      <Trans id="billing-request.heading.becs">
        <Text weight={FontWeight.SemiBold}>
          Set up a Direct Debit Request with{" "}
        </Text>
        <Text weight={FontWeight.Normal}>{creditorName}</Text>
      </Trans>
    ),
    "billing-request.footer.direct-debit-guarantee": () => null,
    "billing-request.footer.gocardless-registration-information": ({
      payerTheme,
      isDropinMode,
    }) => (
      <Trans>
        <P>
          GoCardless Ltd (company registration number 07495895) is registered as
          a foreign company in Australia, ABN 17 606 261 74, and holds an
          Australian Financial Services licence (AFSL), number 478976.
        </P>

        <P>
          GoCardless uses personal data as described in our{" "}
          <BrandedLink
            textColor={getBrandColorFor(
              BrandedComponentType.FooterLink,
              payerTheme,
              isDropinMode
            )}
            variant={ButtonVariant.Inline}
            href="https://gocardless.com/privacy/"
            target="_blank"
          >
            Privacy Notice.
          </BrandedLink>
        </P>
      </Trans>
    ),
    "confirm-details-page.instruction-title": () => (
      <Trans id="confirm-details-page.instruction-title.becs">
        BECS Direct Debit Request
      </Trans>
    ),
    "confirm-details-page.form.confirm-button": () => (
      <Trans id="confirm-details-page.confirm-button.becs">
        Set up this Direct Debit Request
      </Trans>
    ),
    "confirm-details-page.form.view-direct-debit-instructions": () => (
      <Trans id="confirm-details-page.form.view-direct-debit-instructions.becs">
        View your Direct Debit Request and Service Agreement
      </Trans>
    ),
    "confirm-details-page.form.view-gocardless-e-sign-terms": () => null,
    "confirm-details-page.introduction": () => null,
    "confirm-details-page.creditor-name": () => (
      <Trans id="confirm-details-page.creditor-name.becs">
        Creditor&apos;s name
      </Trans>
    ),
    "confirm-details-page.creditor-reference": () => (
      <Trans id="confirm-details-page.creditor-reference.becs">Creditor</Trans>
    ),
    "confirm-details-page.creditor-address": () => null,
    "confirm-details-page.instruction-type": () => null,
    "confirm-details-page.instruction-reference": () => null,
    "confirm-details-page.instruction-type-recurrent": () => null,
    "confirm-details-page.instruction-reference-after-confirmation": () => null,
    "confirm-details-page.date": () => (
      <Trans id="confirm-details-page.date.becs">Date</Trans>
    ),
    "confirm-details-page.bank-name": () => (
      <Trans id="confirm-details-page.bank-name.becs">Bank name</Trans>
    ),
    "confirm-details-page.checkbox-panel.info": () => (
      <Trans id="confirm-details-page.checkbox-panel.info.becs">
        If more than one person is required to authorise Direct Debit Requests
        on this account you should obtain the authorisation of all required
        parties before completing this form. By clicking “Set up this Direct
        Debit Request” you are confirming you have obtained this authorisation
      </Trans>
    ),
    "confirm-details-page.footer.legal-notice": ({
      sunBankStatementName,
      creditorName,
    }) => (
      <Trans id="confirm-details-page.footer.legal-notice.becs">
        <PWithSpacing>
          {sunBankStatementName} will appear on your bank statement.
        </PWithSpacing>
        <PWithSpacing>
          By signing and/or providing us with a valid instruction in respect to
          your Direct Debit Request, you have understood and agreed to the terms
          and conditions governing the debit arrangements between you and{" "}
          {creditorName} as set out in this Request and in your Direct Debit
          Request Service Agreement.
        </PWithSpacing>
        <PWithSpacing>
          You agree to execute this document by electronic signature and you are
          aware that by electronically signing this document you are executing a
          legally binding document.
        </PWithSpacing>
      </Trans>
    ),
    "collect-bank-account-page.bank-details-panel.heading": () => (
      <Trans id="collect-bank-account-page.bank-details-panel.heading.becs">
        Payments are securely powered by GoCardless
      </Trans>
    ),
    "collect-bank-account-page.bank-details-panel.logo": () => (
      <Image
        name="BankLogo"
        alt="direct debit"
        width={REST_BANK_LOGO_WIDTH}
        height={REST_BANK_LOGO_HEIGHT}
        viewBox={REST_BANK_LOGO_VIEWBOX}
      />
    ),
    "billing-request-description-info-scheme-logo": () => null,
    "success-page.header": ({ creditor_name }: { creditor_name: string }) => (
      <Trans id="success-page.header.becs">
        Your Direct Debit Request with {creditor_name} was set up successfully.
      </Trans>
    ),
    "success-page.bank-statement-warning": ({ sunBankStatementName }) => (
      <Trans id="success-page.bank-statement-warning.becs">
        <strong>{sunBankStatementName}</strong> will appear on your bank
        statement when payments are taken against this Direct Debit Request.
      </Trans>
    ),
    "billing-request-description-info": ({ fallen_back }) =>
      fallen_back ? (
        <Trans id="billing-request-description-fallback.becs">
          It looks like your bank doesnt support PayTo. But don&apos;t worry,
          we&apos;ll set up a BECS Direct Debit request for you instead.
          We&apos;ll email you to confirm the amount before future payments are
          taken.
        </Trans>
      ) : (
        <Trans id="billing-request-description-info.becs">
          Your Direct Debit Request will be set up now, but we&apos;ll confirm
          the amount and let you know before future payments are taken.
        </Trans>
      ),
    "billing-request-description-info.restricted-direct-debit-mandate": ({
      creditor_name,
    }: {
      creditor_name: string;
    }) => (
      <Trans id="billing-request-description-info.restricted-direct-debit-mandate.becs">
        This is a restricted Direct Debit Request, so you’re in control. When{" "}
        {creditor_name} wants to charge you we’ll ask for your approval first.
      </Trans>
    ),
    "billing-request-description-dual-flow-mandate-heading": () => (
      <Trans id="billing-request-description-dual-flow-mandate-heading.becs">
        Direct Debit Request setup
      </Trans>
    ),
    "bank-data-access-request-page.ais-notice": () => null,
  },

  [Scheme.PayTo]: {
    "billing-request.heading.dual-flow": ({
      creditorName,
    }: {
      creditorName: string;
    }) => {
      return (
        <Trans id="billing-request.heading.dual-flow">
          <Text weight={600}>Set up your payment to </Text>
          <Space v={0} />
          {creditorName}
        </Trans>
      );
    },
    "billing-request.heading": ({ creditorName }) => (
      <Trans id="billing-request.heading.pay-to">
        <Text weight={FontWeight.SemiBold}>
          Set up a payment agreement with{" "}
        </Text>
        <Text weight={FontWeight.Normal}>{creditorName}</Text>
      </Trans>
    ),
    "billing-request.footer.direct-debit-guarantee": () => null,
    "billing-request.footer.gocardless-registration-information": ({
      payerTheme,
      isDropinMode,
    }) => (
      <Trans>
        <P>
          GoCardless Ltd (company registration number 07495895) is registered as
          a foreign company in Australia, ABN 17 606 261 74, and holds an
          Australian Financial Services licence (AFSL), number 478976.
        </P>

        <P>
          GoCardless uses personal data as described in our{" "}
          <BrandedLink
            textColor={getBrandColorFor(
              BrandedComponentType.FooterLink,
              payerTheme,
              isDropinMode
            )}
            variant={ButtonVariant.Inline}
            href="https://gocardless.com/privacy/"
            target="_blank"
          >
            Privacy Notice.
          </BrandedLink>
        </P>
      </Trans>
    ),
    "confirm-details-page.instruction-title": () => null,
    "confirm-details-page.form.confirm-button": () => null,
    "confirm-details-page.form.view-direct-debit-instructions": () => null,
    "confirm-details-page.form.view-gocardless-e-sign-terms": () => null,
    "confirm-details-page.introduction": () => null,
    "confirm-details-page.creditor-name": () => null,
    "confirm-details-page.creditor-reference": () => null,
    "confirm-details-page.creditor-address": () => null,
    "confirm-details-page.instruction-type": () => null,
    "confirm-details-page.instruction-reference": () => null,
    "confirm-details-page.instruction-type-recurrent": () => null,
    "confirm-details-page.instruction-reference-after-confirmation": () => null,
    "confirm-details-page.date": () => null,
    "confirm-details-page.bank-name": () => null,
    "confirm-details-page.checkbox-panel.info": () => null,
    "confirm-details-page.footer.legal-notice": () => null,
    "collect-bank-account-page.bank-details-panel.heading": () => (
      <Box layout="flex">
        <Icon name={Glyph.PrivacyShield} size="20px" />
        <Space layout="inline" h={0.75} />
        <Trans id="collect-bank-account-page.payment-panel.heading">
          Your payment is securely powered by GoCardless
        </Trans>
      </Box>
    ),
    "collect-bank-account-page.bank-details-panel.logo": () => null,
    "billing-request-description-info-scheme-logo": () => (
      <Image name="PayToLogo" alt="pay to" width="auto" />
    ),
    "success-page.header": () => (
      <Trans id="success-page.header.payment-agreement">
        Your payment agreement was set up successfully
      </Trans>
    ),
    "success-page.bank-statement-warning": () => null,
    "billing-request-description-info": () => null,
    "billing-request-description-info.restricted-direct-debit-mandate": () =>
      null,
    "billing-request-description-dual-flow-mandate-heading": () => null,
    "bank-data-access-request-page.ais-notice": () => null,
  },

  [Scheme.BecsNz]: {
    "billing-request.heading.dual-flow": ({
      creditorName,
    }: {
      creditorName: string;
    }) => {
      return (
        <Trans id="billing-request.heading.dual-flow">
          <Text weight={600}>Set up your payment to </Text>
          <Space v={0} />
          {creditorName}
        </Trans>
      );
    },
    "billing-request.heading": ({ creditorName }) => (
      <Trans id="billing-request.heading.becs-nz">
        <Text weight={FontWeight.SemiBold}>
          Set up a Direct Debit Instruction with{" "}
        </Text>
        <Text weight={FontWeight.Normal}>{creditorName}</Text>
      </Trans>
    ),
    "billing-request.footer.direct-debit-guarantee": () => null,
    "billing-request.footer.gocardless-registration-information": ({
      payerTheme,
      isDropinMode,
    }) => (
      <Trans>
        <P>
          GoCardless Ltd (company registration number 07495895) is registered as
          a foreign company in Australia, ABN 17 606 261 74, and holds an
          Australian Financial Services licence (AFSL), number 478976.
        </P>

        <P>
          GoCardless uses personal data as described in our{" "}
          <BrandedLink
            textColor={getBrandColorFor(
              BrandedComponentType.FooterLink,
              payerTheme,
              isDropinMode
            )}
            variant={ButtonVariant.Inline}
            href="https://gocardless.com/privacy/"
            target="_blank"
          >
            Privacy Notice.
          </BrandedLink>
        </P>
      </Trans>
    ),
    "confirm-details-page.instruction-title": () => (
      <Trans id="confirm-details-page.instruction-title.becs-nz">
        Direct Debit Instruction
      </Trans>
    ),
    "confirm-details-page.form.confirm-button": () => (
      <Trans id="confirm-details-page.confirm-button.becs_nz">
        Set up this Direct Debit Instruction
      </Trans>
    ),
    "confirm-details-page.form.view-direct-debit-instructions": () => (
      <Trans id="confirm-details-page.form.view-direct-debit-instructions.becs_nz">
        View your Direct Debit Instruction and Terms &amp; Conditions
      </Trans>
    ),
    "confirm-details-page.form.view-gocardless-e-sign-terms": () => null,
    "confirm-details-page.introduction": () => null,
    "confirm-details-page.creditor-name": () => (
      <Trans id="confirm-details-page.creditor-name.becs-nz">
        Creditor&apos;s name
      </Trans>
    ),
    "confirm-details-page.creditor-reference": () => (
      <Trans id="confirm-details-page.creditor-reference.becs-nz">
        Authorisation code
      </Trans>
    ),
    "confirm-details-page.creditor-address": () => null,
    "confirm-details-page.instruction-type": () => null,
    "confirm-details-page.instruction-reference": () => null,
    "confirm-details-page.instruction-type-recurrent": () => null,
    "confirm-details-page.instruction-reference-after-confirmation": () => null,
    "confirm-details-page.date": () => (
      <Trans id="confirm-details-page.date.becs-nz">Date</Trans>
    ),
    "confirm-details-page.bank-name": () => (
      <Trans id="confirm-details-page.bank-name.becs-nz">Bank name</Trans>
    ),
    "confirm-details-page.checkbox-panel.info": () => null,
    "confirm-details-page.footer.legal-notice": ({ sunBankStatementName }) => (
      <Trans id="confirm-details-page.footer.legal-notice.becs-nz">
        {sunBankStatementName} will appear on your bank statement.
      </Trans>
    ),
    "collect-bank-account-page.bank-details-panel.heading": () => (
      <Trans id="collect-bank-account-page.bank-details-panel.heading.becs-nz">
        Payments are securely powered by GoCardless
      </Trans>
    ),
    "collect-bank-account-page.bank-details-panel.logo": () => (
      <Image
        name="BankLogo"
        alt="direct debit"
        width={REST_BANK_LOGO_WIDTH}
        height={REST_BANK_LOGO_HEIGHT}
        viewBox={REST_BANK_LOGO_VIEWBOX}
      />
    ),
    "billing-request-description-info-scheme-logo": () => null,
    "success-page.header": ({ creditor_name }: { creditor_name: string }) => (
      <Trans id="success-page.header.becs-nz">
        Your Direct Debit Instruction with {creditor_name} was set up
        successfully.
      </Trans>
    ),
    "success-page.bank-statement-warning": ({ sunBankStatementName }) => (
      <Trans id="success-page.bank-statement-warning.becs-nz">
        <strong>{sunBankStatementName}</strong> will appear on your bank
        statement when payments are taken against this Direct Debit Instruction.
      </Trans>
    ),
    "billing-request-description-info": () => (
      <Trans id="billing-request-description-info.becs-nz">
        Your Direct Debit Instruction will be set up now, but we&apos;ll confirm
        the amount and let you know before future payments are taken.
      </Trans>
    ),
    "billing-request-description-info.restricted-direct-debit-mandate": ({
      creditor_name,
    }: {
      creditor_name: string;
    }) => (
      <Trans id="billing-request-description-info.restricted-direct-debit-mandate.becs-nz">
        This is a restricted Direct Debit Instruction, so you’re in control.
        When {creditor_name} wants to charge you we’ll ask for your approval
        first.
      </Trans>
    ),
    "billing-request-description-dual-flow-mandate-heading": () => (
      <Trans id="billing-request-description-dual-flow-mandate-heading.becs-nz">
        Direct Debit Instruction setup
      </Trans>
    ),
    "bank-data-access-request-page.ais-notice": () => null,
  },

  [Scheme.Betalingsservice]: {
    "billing-request.heading.dual-flow": ({
      creditorName,
    }: {
      creditorName: string;
    }) => {
      return (
        <Trans id="billing-request.heading.dual-flow">
          <Text weight={600}>Set up your payment to </Text>
          <Space v={0} />
          {creditorName}
        </Trans>
      );
    },
    "billing-request.heading": ({ creditorName }) => (
      <Trans id="billing-request.heading.betalingsservice">
        <Text weight={FontWeight.SemiBold}>Set up a Direct Debit with </Text>
        <Text weight={FontWeight.Normal}>{creditorName}</Text>
      </Trans>
    ),
    "billing-request.footer.direct-debit-guarantee": () => null,
    "billing-request.footer.gocardless-registration-information": ({
      payerTheme,
      isDropinMode,
    }) => (
      <Trans>
        <P>
          GoCardless SAS (company registration number 834 422 180, R.C.S. PARIS)
          is authorised by the ACPR (French Prudential Supervision and
          Resolution Authority), Bank Code (CIB) 17118, for the provision of
          payment services.
        </P>

        <P>
          GoCardless uses personal data as described in our{" "}
          <BrandedLink
            textColor={getBrandColorFor(
              BrandedComponentType.FooterLink,
              payerTheme,
              isDropinMode
            )}
            variant={ButtonVariant.Inline}
            href="https://gocardless.com/privacy/"
            target="_blank"
          >
            Privacy Notice.
          </BrandedLink>
        </P>
      </Trans>
    ),
    "confirm-details-page.form.confirm-button": () => (
      <Trans id="confirm-details-page.confirm-button.betalingsservice">
        Set up this Direct Debit
      </Trans>
    ),
    "confirm-details-page.form.view-direct-debit-instructions": () => (
      <Trans id="confirm-details-page.form.view-direct-debit-instructions.betalingsservice">
        View your Direct Debit instruction
      </Trans>
    ),
    "confirm-details-page.form.view-gocardless-e-sign-terms": () => null,
    "confirm-details-page.instruction-title": () => null,
    "confirm-details-page.introduction": () => null,
    "confirm-details-page.creditor-name": () => null,
    "confirm-details-page.creditor-reference": () => null,
    "confirm-details-page.creditor-address": () => null,
    "confirm-details-page.instruction-type": () => null,
    "confirm-details-page.instruction-reference": () => null,
    "confirm-details-page.instruction-type-recurrent": () => null,
    "confirm-details-page.instruction-reference-after-confirmation": () => null,
    "confirm-details-page.date": () => null,
    "confirm-details-page.bank-name": () => null,
    "confirm-details-page.checkbox-panel.info": () => null,
    "confirm-details-page.footer.legal-notice": ({ sunBankStatementName }) => (
      <Trans id="confirm-details-page.footer.legal-notice.betalingsservice">
        {sunBankStatementName} will appear on your bank statement.
      </Trans>
    ),
    "collect-bank-account-page.bank-details-panel.heading": () => (
      <Trans id="collect-bank-account-page.bank-details-panel.heading.betalingsservice">
        Payments are securely powered by GoCardless
      </Trans>
    ),
    "collect-bank-account-page.bank-details-panel.logo": () => (
      <Image
        name="BankLogo"
        alt="direct debit"
        width={REST_BANK_LOGO_WIDTH}
        height={REST_BANK_LOGO_HEIGHT}
        viewBox={REST_BANK_LOGO_VIEWBOX}
      />
    ),
    "billing-request-description-info-scheme-logo": () => null,
    "success-page.header": ({ creditor_name }: { creditor_name: string }) => (
      <Trans id="success-page.header.betalingsservice">
        Your Direct Debit with {creditor_name} was set up successfully.
      </Trans>
    ),
    "success-page.bank-statement-warning": ({ sunBankStatementName }) => (
      <Trans id="success-page.bank-statement-warning.betalingsservice">
        <strong>{sunBankStatementName}</strong> will appear on your bank
        statement when payments are taken against this Direct Debit.
      </Trans>
    ),
    "billing-request-description-info": () => (
      <Trans>
        Your Direct Debit will be set up now. We&apos;ll confirm the amount and
        let you know before future payments are taken.
      </Trans>
    ),
    "billing-request-description-info.restricted-direct-debit-mandate": ({
      creditor_name,
    }: {
      creditor_name: string;
    }) => (
      <Trans id="billing-request-description-info.restricted-direct-debit-mandate.betalingsservice">
        This is a restricted Direct Debit, so you’re in control. When{" "}
        {creditor_name} wants to charge you we’ll ask for your approval first.
      </Trans>
    ),
    "billing-request-description-dual-flow-mandate-heading": () => (
      <Trans id="billing-request-description-dual-flow-mandate-heading.betalingsservice">
        Direct Debit setup
      </Trans>
    ),
    "bank-data-access-request-page.ais-notice": () => null,
  },

  [Scheme.Pad]: {
    "billing-request.heading.dual-flow": ({
      creditorName,
      consentType,
    }: {
      creditorName: string;
      consentType: ConsentType;
    }) => {
      if (consentType === ConsentType.Sporadic) {
        return (
          <>
            <Text weight={FontWeight.Bold}>
              Set up a sporadic Pre-Authorized Debit Agreement with
            </Text>{" "}
            {creditorName}
          </>
        );
      }
      return (
        <Trans id="billing-request.heading.dual-flow">
          <Text weight={600}>Set up your payment to </Text>
          {creditorName}
        </Trans>
      );
    },
    "billing-request.heading": ({ creditorName }) => (
      <Trans id="billing-request.heading.pad">
        <Text weight={FontWeight.SemiBold}>
          Set up a Pre-Authorized Debit with{" "}
        </Text>
        <Text weight={FontWeight.Normal}>{creditorName}</Text>
      </Trans>
    ),
    "billing-request.footer.direct-debit-guarantee": () => null,
    "billing-request.footer.gocardless-registration-information": ({
      payerTheme,
      isDropinMode,
      i18n,
    }: {
      payerTheme: PayerThemeType;
      isDropinMode: boolean;
      i18n: I18n;
    }) => {
      const brandColor = getBrandColorFor(
        BrandedComponentType.FooterLink,
        payerTheme,
        isDropinMode
      );
      return (
        <Interpose node={<Space v={1} />}>
          <Trans>
            GoCardless Ltd (company registration number 07495895) is authorised
            by the Financial Conduct Authority under the Payment Services
            Regulations 2017, registration number 597190, for the provision of
            payment services.
          </Trans>
          <Trans>
            GoCardless uses personal data as described in our{" "}
            <PlainLink
              effect={HoverEffect.TextDecoration}
              textDecoration="underline"
              href={getTranslatedLink(Link.Privacy, i18n)}
              target="_blank"
              css={brandColor && { ...CSSForLinkAppearance(brandColor) }}
            >
              Privacy Notice.
            </PlainLink>
          </Trans>
        </Interpose>
      );
    },
    "confirm-details-page.form.confirm-button": () => (
      <Trans>Confirm this PAD Agreement</Trans>
    ),
    "confirm-details-page.form.view-direct-debit-instructions": () => (
      <Trans id="confirm-details-page.form.view-direct-debit-instructions.pad">
        View your Pre-Authorized Debit agreement and terms &amp; conditions
      </Trans>
    ),
    "confirm-details-page.form.view-gocardless-e-sign-terms": () => null,
    "confirm-details-page.instruction-title": () => (
      <Trans id="confirm-details-page.instruction-title.pad">
        Pre-Authorized Debit agreement
      </Trans>
    ),
    "confirm-details-page.introduction": ({ creditorName, merchantEmail }) => (
      <Trans>
        <Interpose node={<Space v={1} />}>
          <Text>
            Please check the details and copy of your Pre-Authorized Debit
            provided below are correct. Details of your payments will be
            provided via email notification.
          </Text>
          <Text>
            You can revoke your Pre-Authorized Debit by emailing {creditorName}{" "}
            at {merchantEmail}. This will be revoked in accordance with{" "}
            {creditorName}&apos;s timings, and scheduled debits due during this
            time may still be honored.
          </Text>
        </Interpose>
      </Trans>
    ),
    "confirm-details-page.creditor-name": () => null,
    "confirm-details-page.creditor-reference": () => null,
    "confirm-details-page.creditor-address": () => null,
    "confirm-details-page.instruction-type": () => null,
    "confirm-details-page.instruction-reference": () => null,
    "confirm-details-page.instruction-type-recurrent": () => null,
    "confirm-details-page.instruction-reference-after-confirmation": () => null,
    "confirm-details-page.date": () => (
      <Trans id="confirm-details-page.date.pad">Date</Trans>
    ),
    "confirm-details-page.bank-name": () => null,
    "confirm-details-page.checkbox-panel.info": () => null,
    "confirm-details-page.footer.legal-notice": ({ sunBankStatementName }) => (
      <Trans id="confirm-details-page.footer.legal-notice.pad">
        {sunBankStatementName} will appear on your bank statement.
      </Trans>
    ),
    "collect-bank-account-page.bank-details-panel.heading": () => (
      <Trans id="collect-bank-account-page.bank-details-panel.heading.pad">
        Payments are securely powered by GoCardless
      </Trans>
    ),
    "collect-bank-account-page.bank-details-panel.logo": () => (
      <Image
        name="BankLogo"
        alt="direct debit"
        data-testid="direct debit"
        width={24}
        height={20}
        viewBox="0 0 24 20"
      />
    ),
    "billing-request-description-info-scheme-logo": () => (
      <Image
        name="BankLogo"
        alt="direct debit"
        data-testid="direct debit"
        width={24}
        height={20}
        viewBox="0 0 24 20"
      />
    ),
    "success-page.header": ({ creditor_name }: { creditor_name: string }) => (
      <Trans id="success-page.header.pad">
        Your Pre-Authorized Debit with {creditor_name} was set up successfully.
      </Trans>
    ),
    "success-page.bank-statement-warning": ({ sunBankStatementName }) => (
      <Trans id="success-page.bank-statement-warning.pad">
        <strong>{sunBankStatementName}</strong> will appear on your bank
        statement when payments are taken against this Pre-Authorized Debit.
      </Trans>
    ),
    "billing-request-description-info": ({
      creditor_name,
    }: {
      creditor_name: string;
    }) => (
      <Trans>
        Your Pre-Authorized Debit will be set up now in accordance with the
        amount and timings agreed with {creditor_name}.
      </Trans>
    ),
    "billing-request-description-info.restricted-direct-debit-mandate": ({
      creditor_name,
    }: {
      creditor_name: string;
    }) => (
      <Trans id="billing-request-description-info.restricted-direct-debit-mandate.pad">
        This is a restricted Pre-Authorized Debit, so you’re in control. When{" "}
        {creditor_name} wants to charge you we’ll ask for your approval first.
      </Trans>
    ),
    "billing-request-description-dual-flow-mandate-heading": () => (
      <Trans id="billing-request-description-dual-flow-mandate-heading.pad">
        Pre-Authorized Debit setup
      </Trans>
    ),
    "bank-data-access-request-page.ais-notice": () => null,
  },

  [Scheme.SepaCore]: {
    "billing-request.heading.dual-flow": ({
      creditorName,
    }: {
      creditorName: string;
    }) => {
      return (
        <Trans id="billing-request.heading.dual-flow">
          <Text weight={600}>Set up your payment to </Text>
          <Space v={0} />
          {creditorName}
        </Trans>
      );
    },
    "billing-request.heading": ({ creditorName }) => (
      <Trans id="billing-request.heading.sepa">
        <Text weight={FontWeight.SemiBold}>Set up a Direct Debit with </Text>
        <Text weight={FontWeight.Normal}>{creditorName}</Text>
      </Trans>
    ),
    "billing-request.footer.direct-debit-guarantee": () => null,
    "billing-request.footer.gocardless-registration-information": ({
      payerTheme,
      isDropinMode,
    }) => (
      <Trans>
        <P>
          GoCardless SAS (company registration number 834 422 180, R.C.S. PARIS)
          is authorised by the ACPR (French Prudential Supervision and
          Resolution Authority), Bank Code (CIB) 17118, for the provision of
          payment services.
        </P>

        <P>
          GoCardless uses personal data as described in our{" "}
          <BrandedLink
            textColor={getBrandColorFor(
              BrandedComponentType.FooterLink,
              payerTheme,
              isDropinMode
            )}
            variant={ButtonVariant.Inline}
            href="https://gocardless.com/privacy/"
            target="_blank"
          >
            Privacy Notice.
          </BrandedLink>
        </P>
      </Trans>
    ),
    "confirm-details-page.instruction-title": () => (
      <Trans id="confirm-details-page.instruction-title.sepa">
        SEPA Core Direct Debit Mandate
      </Trans>
    ),
    "confirm-details-page.form.confirm-button": () => (
      <Trans id="confirm-details-page.confirm-button.sepa">
        Set up this Direct Debit
      </Trans>
    ),
    "confirm-details-page.form.view-direct-debit-instructions": () => (
      <Trans id="confirm-details-page.form.view-direct-debit-instructions.sepa">
        View your Direct Debit instruction
      </Trans>
    ),
    "confirm-details-page.form.view-gocardless-e-sign-terms": () => null,
    "confirm-details-page.introduction": () => null,
    "confirm-details-page.creditor-name": () => (
      <Trans id="confirm-details-page.creditor-name.sepa">
        Creditor&apos;s name
      </Trans>
    ),
    "confirm-details-page.creditor-reference": () => (
      <Trans id="confirm-details-page.creditor-reference.sepa">Creditor</Trans>
    ),
    "confirm-details-page.creditor-address": () => (
      <Trans id="confirm-details-page.creditor-address.sepa">Address</Trans>
    ),
    "confirm-details-page.instruction-type": () => (
      <Trans id="confirm-details-page.instruction-type.sepa">Type</Trans>
    ),
    "confirm-details-page.instruction-reference": () => (
      <Trans id="confirm-details-page.instruction-reference.sepa">
        Reference
      </Trans>
    ),
    "confirm-details-page.instruction-type-recurrent": () => (
      <Trans id="confirm-details-page.instruction-type-recurrent.sepa">
        Recurrent
      </Trans>
    ),
    "confirm-details-page.instruction-reference-after-confirmation": () => (
      <Trans id="confirm-details-page.instruction-reference-after-confirmation.sepa">
        Available after confirmation
      </Trans>
    ),
    "confirm-details-page.date": () => (
      <Trans id="confirm-details-page.date.sepa">Date</Trans>
    ),
    "confirm-details-page.bank-name": () => null,
    "confirm-details-page.checkbox-panel.info": () => (
      <Trans id="confirm-details-page.checkbox-panel.info">
        This usually only applies to company bank accounts. If more than one
        person is required to authorise Direct Debits on this account, we will
        need all signatories to sign a paper version of the Direct Debit
        mandate. We’ll give you instructions on how to do this next.
      </Trans>
    ),
    "confirm-details-page.footer.legal-notice": ({
      sunBankStatementName,
      sunAdvanceNotice,
      sunRegisteredName,
    }) => (
      <Trans id="confirm-details-page.footer.legal-notice.sepa">
        <PWithSpacing>
          {sunBankStatementName} will appear on your bank statement.
        </PWithSpacing>
        <PWithSpacing>
          We will notify you via email at least {sunAdvanceNotice} working days
          in advance of any changes to your payment date, frequency or amount.
        </PWithSpacing>
        <PWithSpacing>
          By signing this mandate form, you authorise (A) {sunRegisteredName} to
          send instructions to your bank to debit your account (B) your bank to
          debit your account in accordance with the instructions from{" "}
          {sunRegisteredName}.
        </PWithSpacing>
        <PWithSpacing>
          As part of your rights, you are entitled to a refund from your bank
          under the terms and conditions of your agreement with your bank. A
          refund must be claimed within 8 weeks starting from the date on which
          your account was debited.
        </PWithSpacing>
      </Trans>
    ),
    "collect-bank-account-page.bank-details-panel.heading": () => (
      <Trans id="collect-bank-account-page.bank-details-panel.heading.sepa">
        Payments are securely powered by GoCardless
      </Trans>
    ),
    "collect-bank-account-page.bank-details-panel.logo": () => (
      <Image
        name="SepaLogo"
        alt="direct debit"
        width={SEPA_LOGO_WIDTH}
        height={SEPA_LOGO_HEIGHT}
        viewBox={SEPA_LOGO_VIEWBOX}
      />
    ),
    "billing-request-description-info-scheme-logo": () => (
      <Image
        name="SepaLogo"
        alt="sepa"
        width={SEPA_LOGO_WIDTH}
        height={SEPA_LOGO_HEIGHT}
        viewBox={SEPA_LOGO_VIEWBOX}
      />
    ),
    "success-page.header": ({ creditor_name }: { creditor_name: string }) => (
      <Trans id="success-page.header.sepa">
        Your Direct Debit with {creditor_name} was set up successfully.
      </Trans>
    ),
    "success-page.bank-statement-warning": ({ sunBankStatementName }) => (
      <Trans id="success-page.bank-statement-warning.sepa">
        <strong>{sunBankStatementName}</strong> will appear on your bank
        statement when payments are taken against this Direct Debit.
      </Trans>
    ),
    "billing-request-description-info": () => (
      <Trans>
        Your Direct Debit will be set up now. We&apos;ll confirm the amount and
        let you know before future payments are taken.
      </Trans>
    ),
    "billing-request-description-info.restricted-direct-debit-mandate": ({
      creditor_name,
    }: {
      creditor_name: string;
    }) => (
      <Trans id="billing-request-description-info.restricted-direct-debit-mandate.sepa">
        This is a restricted Direct Debit, so you’re in control. When{" "}
        {creditor_name} wants to charge you we’ll ask for your approval first.
      </Trans>
    ),
    "billing-request-description-dual-flow-mandate-heading": () => (
      <Trans id="billing-request-description-dual-flow-mandate-heading.autogiro">
        Direct Debit setup
      </Trans>
    ),
    "bank-data-access-request-page.ais-notice": ({
      authority,
    }: {
      authority: string;
    }) => (
      <P size={2} data-testid="ais-notice">
        <Trans id="bank-data-access-request-page.ais-notice">
          *GoCardless will use its {authority} Account Information Service
          permissions to do this, and use the information to set up your direct
          debit more securely.
        </Trans>
      </P>
    ),
  },
};
