import {
  XYGrid,
  P,
  FontWeight,
  AlignItems,
  JustifyContent,
  ColorPreset,
  Box,
  ButtonVariant,
  ButtonSize,
  Glyph,
  TypePreset,
  H2,
  Text,
} from "@gocardless/flux-react";
import { ReactElement, useContext } from "react";
import { PaymentVariant } from "src/common/payments";
import { getBrandColorFor, BrandedComponentType } from "src/common/utils";
import Amount from "src/legacy-ui/amount";
import { GlobalState } from "src/state";
import { Trans } from "@lingui/macro";
import {
  AvailableDebitSchemeEnum,
  ConsentType,
} from "@gocardless/api/dashboard/types";
import { Scheme } from "src/common/scheme";
import { getDisplayedConsentInfo } from "src/common/scheme-translations/DisplayedConsentInformation/getDisplayedConsentInfo";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";

import BrandedButton from "../../BrandedComponents/BrandedButton";
import { Routes } from "../../Router";
import { ConsentTypeTag } from "../../components/ConsentTypeTag";

interface PaymentDescriptionProps {
  paymentVariant: PaymentVariant | null;
  currency: string | undefined;
  amountInPence: number | undefined;
  schemeLogo?: ReactElement | null;
  description: string | null | undefined;
  isAmountFlexible?: boolean;
  page?: Routes;
  scheme: Scheme;
  consentType?: ConsentType | undefined;
  creditorName?: string | undefined;
}

const getBottomText = (creditorName?: string, consentType?: ConsentType) => {
  const variableConsentTypes = [ConsentType.Standing, ConsentType.Sporadic];
  if (!consentType || !variableConsentTypes.includes(consentType)) {
    return (
      <P size={1} color={ColorPreset.TextOnLight_02}>
        Payment will be collected as soon as possible, usually within a few days
      </P>
    );
  }

  return (
    <P
      spaceAbove={[0, null, 0.5]}
      size={[1, null, 2]}
      color={ColorPreset.TextOnLight_01}
    >
      When <Text weight={FontWeight.Bold}>{creditorName}</Text> wants to charge
      you in future, we&apos;ll send you an email asking for your approval first
    </P>
  );
};

export const PaymentDescription = ({
  consentType,
  creditorName,
  paymentVariant,
  currency = "GBP",
  amountInPence = 0,
  schemeLogo,
  description,
  isAmountFlexible,
  page,
  scheme,
}: PaymentDescriptionProps) => {
  const { push, payerTheme, setIsChangeAmountLinkClicked } =
    useContext(GlobalState);
  const { consentTypeText, toolTipContent } = getDisplayedConsentInfo(
    scheme,
    ConsentType.OneOff
  );
  const showChangeAmountButton =
    isAmountFlexible && page !== Routes.CollectAmount;
  const displayedConsentType = consentTypeText || (
    <Trans id="billing-request-description.payment-description.one-off-payment">
      One-off payment
    </Trans>
  );
  const isAchOrPad = [
    AvailableDebitSchemeEnum.Ach,
    AvailableDebitSchemeEnum.Pad,
  ].includes(scheme);

  const bottomText = isAchOrPad
    ? getBottomText(creditorName, consentType)
    : undefined;

  return (
    <XYGrid rowGap={0.5}>
      <H2 preset={TypePreset.Heading_03} weight={FontWeight.SemiBold}>
        {description}
      </H2>
      <XYGrid
        templateColumns={
          paymentVariant === PaymentVariant.DirectDebitOneOff
            ? "auto auto auto 1fr"
            : "auto auto 1fr"
        }
        columnGap={1}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Start}
      >
        <Amount currency={currency} amountInPence={amountInPence} />
        <ConsentTypeTag
          displayedConsentType={displayedConsentType}
          toolTipContent={toolTipContent}
        />
        {showChangeAmountButton && (
          <Box css={{ justifySelf: "end" }}>
            <BrandedButton
              textColor={getBrandColorFor(
                BrandedComponentType.Link,
                payerTheme
              )}
              variant={ButtonVariant.Inline}
              onClick={() => {
                setIsChangeAmountLinkClicked(true);
                push(Routes.CollectAmount, {
                  origin: "BillingRequestDescription",
                  reason: "clicked change amount link",
                });
              }}
              size={ButtonSize.Sm}
              leftIcon={Glyph.Edit}
              id="changeCustomer"
            >
              <Trans id="confirm-details-page.form.change-button">Change</Trans>
            </BrandedButton>
          </Box>
        )}
        {schemeLogo && !isAchOrPad && (
          <Box css={{ justifySelf: "end" }}>{schemeLogo}</Box>
        )}
      </XYGrid>
      {bottomText && (
        <Box layout="flex" alignItems={AlignItems.End}>
          {bottomText}
          <Box layout="flex" spaceBefore={[0, null, 2]}>
            {translateForScheme({
              scheme,
              translationKey: "billing-request-description-info-scheme-logo",
              params: {},
            })}
          </Box>
        </Box>
      )}
    </XYGrid>
  );
};
