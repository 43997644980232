import { Scheme } from "..";

import { PaymentScheme, PaymentSchemeMap } from "./PaymentSchemeCustomisations";

export const isIncludedPaymentScheme = (
  scheme?: Scheme | PaymentScheme
): scheme is PaymentScheme => {
  if (!scheme) {
    return false;
  }
  const includedPaymentSchemes = Object.values(PaymentSchemeMap).map((value) =>
    value.toString()
  );

  return includedPaymentSchemes.includes(scheme.toString());
};
